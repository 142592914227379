import _ from 'lodash'
import * as XLSX from 'xlsx';
import cronstrue from 'cronstrue';
import fetchRequest from "utils/fetchRequest";
import numeral from 'numeral';
import moment from 'moment';
// import { useYADialog } from 'components/YADialog';
import { providerNameVariations } from 'components/VisualizationRenderer/components/ChartRenderer/constants';
const assetName = [
    { singular: "application", plural: "applications" },
    { singular: "product", plural: "products" },
    { singular: "service", plural: "services" },
    { singular: "cloud", plural: "cloud" },
    { singular: "computer", plural: "computers" },
    { singular: "database", plural: "databases" },
    { singular: "datacentre", plural: "datacenters" },
    { singular: "datacentres", plural: "datacenters" },
    { singular: "datacenter", plural: "datacenters" },
    { singular: "employee", plural: "employees" },
    { singular: "hardware", plural: "hardware" },
    { singular: "mainframe", plural: "mainframes" },
    { singular: "mainframestorage", plural: "mainframestorage" },
    { singular: "middleware", plural: "middleware" },
    { singular: "networkdevice", plural: "networkdevices" },
    { singular: "phonenumber", plural: "phonenumbers" },
    { singular: "printer", plural: "printers" },
    { singular: "project", plural: "projects" },
    { singular: "server", plural: "servers" },
    { singular: "storage", plural: "storage" },
    { singular: "software", plural: "software" },
    { singular: "valuestream", plural: "valuestream" },
]

// const { showAlert } = useYADialog();

export const pickColorNum = (value) => {
    let sum = 0;
    let value1 = value?.toUpperCase();
    for (let i = 0; i < value1.length; i++) {
        sum += value1[i]?.charCodeAt();
    }
    let num = Math.abs((sum + value1.length) % 36);
    return num
}

const parseCronExpression = (expression) => {
    try {
        return cronstrue.toString(expression)
    } catch {
        return '';
    }
};

const convertPlural = (assets) => {
    if (assets) {
        let convertedName = assetName.find(asset => asset.singular.toLowerCase() === assets.toLowerCase().replaceAll(" ", ""))?.plural.toLowerCase() ?? assets.toLowerCase().replaceAll(" ", "");
        return convertedName
    }
}

const formatNum = (decimalPlaces) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: decimalPlaces,
    });
}

const showFullDecimal = (num) => {
    //if the number is in exponential notation...
    if (num?.toString()?.includes('e')) {
        const dn = num?.toFixed(20)
        return dn?.replace(/0+$/, '');
    } else {
        return num;
    }
}

const applyTemplateVariables = (jsonStr, variables) => {
    let returnValStr = jsonStr;
    if (typeof jsonStr === 'object')
        returnValStr = JSON.stringify(jsonStr);

    const compiledTemplate = _.template(returnValStr);
    returnValStr = compiledTemplate(variables || {});
    return returnValStr;
};

const applyVariablesToObject = (jsonStr, variables) => {
    let returnValStr = jsonStr;
    if (typeof jsonStr === 'object')
        returnValStr = JSON.stringify(jsonStr);

    const compiledTemplate = _.template(returnValStr);
    returnValStr = compiledTemplate(variables || {});
    return parseJsonString(returnValStr);
};

const formatAlertTiggerSchedule = (frequency, hour, day, month) => {
    if (frequency === "DAILY")
        return "Every day at 12 AM";
    if (frequency === "HOURLY") {
        if ((hour || "1") === "1")
            return `Every hour`;
        return `Every ${hour || 1} hours`;
    }
    if (frequency === "MONTHLY") {
        if ((month || "1") === "1")
            return `Every month on day ${day || 1}`;
        return `Every ${month || 1} months on day ${day || 1}`;
    }
    return null;
};

const formatAlertMetricValue = (value, dataType) => {
    if (dataType === "Integer") {
        return isNaN(value) ? '0' : numeral(value).format('0');
    }
    else if (dataType === "Decimal") {
        return isNaN(value) ? '0' : numeral(value).format('0,0.00');
    }
    else if (dataType === "Currency") {
        return isNaN(value) ? '$0' : formatAmount(value, 0)?.replaceAll(" ", "");
    }
    else if (dataType === "Percentage") {
        return isNaN(value) ? '0%' : numeral(Number(value) / 100).format('0.00%');
    }
    return String(value || '');
};

const getDomain = () => {
    return window.location.origin;
}

const replaceEmailId = (email) => {
    const [, domain] = email.split('@')
    let userNames = `#@${domain}`;
    return userNames
}

const getPageName = () => {
    let PageName = window.location.href;
    PageName.lastIndexOf('/');
    return PageName.substring(PageName.lastIndexOf('/') + 1).toLowerCase();
}

const joinCodeDesc = (code, desc) => `${code || ""} ${desc || ""}`;

const generateUUID = () => {
    let d = new Date().getTime();//Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now) ? (performance.now() * 1000) : 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
    });
}

const parseJsonString = (jsonString) => {
    try {
        return jsonString ? JSON.parse(jsonString) : jsonString;
    } catch (e) {
        console.error(e)
        return null;
    }
};

const formatAmount = (value, significantDigits) => {
    var sd = significantDigits ? significantDigits : 1;

    if (value >= 1000000000)
        return '$ ' + (value / 1000000000).toFixed(sd) + ' B'
    if (value >= 1000000)
        return '$ ' + (value / 1000000).toFixed(sd) + ' M'
    if (value >= 1000)
        return '$ ' + (value / 1000).toFixed(sd) + ' K'
    if (value <= -1000000000)
        return '$ ' + (value / 1000000000).toFixed(sd) + ' B'
    if (value <= -1000000)
        return '$ ' + (value / 1000000).toFixed(sd) + ' M'
    if (value <= -1000)
        return '$ ' + (value / 1000).toFixed(sd) + ' K'
    if ((Number(value) < 1 && Number(value) > -1) && Number(value) !== 0)
        return '$ ' + Number(value).toFixed(2)
    if (Number(value) !== 0)
        return '$ ' + Number(value).toFixed(1)
    return '$ ' + Number(value).toFixed(0)
}

const formatNumber = (value, significantDigits) => {
    var sd = significantDigits ? significantDigits : 1;

    if (value >= 1000000000)
        return (value / 1000000000).toFixed(sd) + ' B'
    if (value >= 1000000)
        return (value / 1000000).toFixed(sd) + ' M'
    if (value >= 1000)
        return (value / 1000).toFixed(sd) + ' K'
    if (value <= -1000000000)
        return (value / 1000000000).toFixed(sd) + ' B'
    if (value <= -1000000)
        return (value / 1000000).toFixed(sd) + ' M'
    if (value <= -1000)
        return (value / 1000).toFixed(sd) + ' K'
    if ((Number(value) < 1 && Number(value) > -1) && Number(value) !== 0)
        return Number(value).toFixed(2)
    if (Number(value) !== 0)
        return Number(value).toFixed(1)
    return Number(value).toFixed(0)
}

const formatTooltip1 = (value) => {
    if ((Number(value) < 100 && Number(value) > -1) && Number(value) !== 0)
        return '$ ' + Number(value).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    return '$ ' + Number(value).toLocaleString("en", { maximumFractionDigits: 0 })
}

const formatTooltip2 = (value) => {
    if ((Number(value) < 100 && Number(value) > -1) && Number(value) !== 0)
        return Number(value).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    return Number(value).toLocaleString("en", { maximumFractionDigits: 0 })
}

const formatInteger = (val) => {
    if (val % 1 !== 0) {
        let roundedValue = Math.floor(val * 100) / 100;
        let formattedValue = roundedValue.toFixed(2);
        return Number(formattedValue)
    }
    else return Number(val)
}

const getDrilldownPath = (basePath, drilldownPath) => {
    return basePath?.replace(drilldownPath, "") + drilldownPath;
}

const getDrilldownPath_CF = (basePath, drilldownPath) => {
    return drilldownPath;
}

const defaultOptions = {
    significantDigits: 0,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$'
}

const currencyFormatter = (value, options) => {
    if (typeof value !== 'number') value = 0.0
    options = { ...defaultOptions, ...options }
    value = value.toFixed(options.significantDigits)

    const [currency, decimal] = value.split('.')
    if (options.significantDigits > 0) {
        return `${options.symbol} ${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}${options.decimalSeparator}${decimal}`
    } else {
        return `${options.symbol} ${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}`
    }
}

const applyDefaultFilter = (def) => {
    if (!def["filters"])
        def["filters"] = []
    def.filters.map((filter) => {
        if (filter.defaultValue && filter.defaultValue.length() > 0) {
            def.widgets.map((widget) => {
                if (!widget.vizState.query["filters"])
                    widget.vizState.query["filters"] = []
                let fils = []
                let found = false
                widget.vizState.query["filters"].map((f) => {
                    if (f.member === filter.queryName) {
                        filter.defaultValue.map((val) => {
                            if (filter.session)
                                return f.values = sessionStorage[val]
                            else
                                return f.values = val
                        })
                        found = true
                        return fils.push({ ...f })
                    } else {
                        return fils.push({ ...f })
                    }
                })
                if (!found) {
                    fils.push({
                        "member": filter.queryName,
                        "operator": "equals",
                        "values": filter.defaultValue.map((val) => {
                            if (filter.session)
                                return sessionStorage[val]
                            else
                                return val
                        })
                    })
                }
                return widget.vizState.query["filters"] = fils
            })
            return filter.values = filter.defaultValue.map((val) => {
                if (filter.session)
                    return sessionStorage[val]
                else
                    return val
            })
        }
    })
    return def
}


const applyDefaultFilter1 = (def) => {
    if (!def["filters"])
        def["filters"] = []
    def.filters.forEach((filter) => {
        if (filter.defaultValue && filter.defaultValue.length > 0) {
            def.widgets.forEach((widget) => {
                if (widget.vizState.query.length == undefined) {
                    applyDefaultFilter1ForQuery(filter, widget.vizState.query)
                }
                else {
                    let i = 0;
                    widget.vizState.query.forEach((query) => {
                        if (!(widget.vizOptions.queryType === "allCategories" && filter.queryName === widget.vizOptions.category && i == 0)) {
                            applyDefaultFilter1ForQuery(filter, query)
                        }
                        i++;
                    })
                }
            })
            filter.defaultSelected = true;
            filter.values = filter.session ?
                sessionStorage[filter.defaultValue[0]]?.split(",") :
                filter.defaultValue;
        }
    })
    return def
}

const applyDefaultFilter1ForQuery = (filter, query) => {
    if (!query["filters"])
        query["filters"] = []
    let fils = []
    let found = false
    query["filters"].map((f) => {
        if (f.member === filter.queryName) {
            filter.values = filter.session ?
                sessionStorage[filter.defaultValue[0]]?.split(",") :
                filter.defaultValue;

            found = true
            return fils.push({ ...f })
        } else {
            return fils.push({ ...f })
        }
    })
    if (!found) {
        fils.push({
            "member": filter.queryName,
            "operator": "equals",
            "values": filter.session ?
                sessionStorage[filter.defaultValue[0]]?.split(",") :
                filter.defaultValue
        });
    }
    query["filters"] = fils
}

const deleteSelectedFilter = (def, deletedFilter) => {
    let fils = []
    def.filters.map((filter) => {
        if (filter.name !== deletedFilter.name) {
            return fils.push({ ...filter })
        }
        else {
            filter.values = []
            return fils.push({ ...filter })
        }
    })
    def.filters = fils
    def.widgets.map((widget) => {
        let fils1 = []
        widget.vizState.query["filters"].map((f) => {
            if (f.member !== deletedFilter.query.dimensions[0]) {
                return fils1.push({ ...f })
            }
        })
        return (widget.vizState.query["filters"] = fils1)
    })
    return def
}

const deleteSelectedFilter1 = (def, deletedFilter) => {
    let filter = def.filters?.find(f => f.name === deletedFilter.name);
    if (filter) {
        filter.values = []
        filter.selected = false;
        filter.defaultSelected = false;
    }
    def.widgets.forEach((widget) => {
        if (widget.vizState.query.length == undefined) {
            deleteSelectedFilter1ForQuery(filter, widget.vizState.query)
        }
        else {
            let i = 0;
            widget.vizState.query.forEach((query) => {
                if (!(widget.vizOptions.queryType === "allCategories" && deletedFilter.name === widget.vizOptions.category && i == 0)) {
                    deleteSelectedFilter1ForQuery(filter, query)
                }
                i++;
            })
        }
    })

    return def
}

const deleteSelectedFilter1ForQuery = (filter, query) => {
    const newVizFilters = query["filters"]?.filter(f => filter.query.dimensions[0] !== f.member);
    query["filters"] = newVizFilters;
}

const setSelectedFilter = (def, val, name, member, operator) => {
    def.filters.map((filter) => {
        if (filter.name === name) {
            if (filter.session)
                sessionStorage[filter.name] = val
            filter.values = [val]
        }
    })
    def.widgets.map((widget) => {
        if (!widget.vizState.query["filters"])
            widget.vizState.query["filters"] = []
        let fils = []
        let found = false
        widget.vizState.query["filters"].map((f) => {
            if (f.member === member) {
                f.values = [val]
                found = true
                return fils.push({ ...f })
            } else {
                return fils.push({ ...f })
            }
        })
        if (!found) {
            fils.push({
                "member": member,
                "operator": operator,
                "values": [val]
            })
        }
        return widget.vizState.query["filters"] = fils
    })
    return def
}

const setSelectedFilter1 = (def, val, name, member, operator) => {
    def.filters.map((filter) => {
        if (filter.name === name) {
            if (filter.session)
                sessionStorage[filter.name] = val;
            filter.values = val;
        }
    })
    def.widgets.map((widget) => {
        if (widget.vizState.query.length == undefined) {
            setSelectedFilter1ForQuery(widget.vizState.query, member, operator, val)
        }
        else {
            let i = 0;
            widget.vizState.query.forEach((query) => {
                if (!(widget.vizOptions.queryType === "allCategories" && member === widget.vizOptions.category && i == 0)) {
                    setSelectedFilter1ForQuery(query, member, operator, val)
                }
                i++;
            })
        }
    })
}

const setSelectedFilter2 = (def, val, name, member, operator, prvMthQuery) => {
    def?.widgets?.map((widget) => {
        if ((member !== "Months.month") || (widget.vizOptions.currentMonthFilter)) {
            if (widget.vizState.query.length == undefined) {
                if (!prvMthQuery)
                    setSelectedFilter1ForQuery(widget.vizState.query, member, operator, val)
            }
            else {
                let qcount = 1;
                widget.vizState.query.forEach((query) => {
                    if (!prvMthQuery || (prvMthQuery && qcount === 2 && widget.vizOptions.previousMonthFilter))
                        setSelectedFilter1ForQuery(query, member, operator, val);
                    // else if (prvMthQuery && qcount === 2)
                    //     setSelectedFilter1ForQuery(query,member,operator,val);
                    qcount++;
                })
            }
        }
    })
}

const setSelectedFilter1ForQuery = (query, member, operator, val) => {
    let selectedFilter = query["filters"]?.find(f => f.member === member);
    if (selectedFilter) {
        selectedFilter.values = val;
    }
    else {
        query["filters"].push({
            "member": member,
            "operator": operator,
            "values": val
        });
    }
}


const setDrilldownFilter = (def, passedFilters) => {
    if (passedFilters && passedFilters.length > 0) {
        def.filters.map((filter) => {
            passedFilters.map((fil) => {
                if (filter.queryName === fil.name)
                    return filter.values = fil.values
            })
        })
        def.widgets.map((widget) => {
            if (!widget.vizState.query["filters"])
                widget.vizState.query["filters"] = []
            widget.vizState.query["filters"].map((f) => {
                let passedFil = _.find(passedFilters, { name: f.member })
                if (passedFil)
                    f.values = passedFil.values
                return null
            })
            // "operator": fil.name.indexOf('vendorId') > 0 ? "set" : "equals",
            passedFilters.map((fil) => {
                if (!_.find(widget.vizState.query["filters"], { member: fil.name })) {
                    let qryDim = widget.vizState.query["measures"][0].substring(0, widget.vizState.query["measures"][0].indexOf('.'))
                    widget.vizState.query["filters"].push({
                        "member": fil.name.indexOf('vendorId') > 0 ? qryDim + ".vendorId" : fil.name,
                        "operator": fil.operator ? fil.operator : "equals",
                        "values": fil.values
                    })
                }
                return null
            })
            return widget.vizState.query["filters"]
        })
    }
    return def
}

const setDrilldownFilter1 = (def, passedFilters) => {
    if (passedFilters && passedFilters.length > 0) {
        def.filters.forEach((filter) => {
            passedFilters.forEach((fil) => {
                if (filter.queryName === fil.name) {
                    filter.values = fil.values;
                    filter.defaultSelected = true;
                }
            })
        })
        def.widgets.forEach((widget) => {
            if (widget.vizState.query.length == undefined) {
                if (!widget.vizState.query["filters"])
                    widget.vizState.query["filters"] = []
                widget.vizState.query["filters"].forEach((f) => {
                    let passedFil = _.find(passedFilters, { name: f.member })
                    if (passedFil) {
                        f.values = passedFil.values
                    }
                    return null
                })
            }
            // "operator": fil.name.indexOf('vendorId') > 0 ? "set" : "equals",
            passedFilters.forEach((fil) => {
                if (widget.vizState.query.length == undefined) {
                    if (!_.find(widget.vizState.query["filters"], { member: fil.name })) {
                        let qryDim = widget.vizState.query["measures"][0].substring(0, widget.vizState.query["measures"][0].indexOf('.'))
                        widget.vizState.query["filters"].push({
                            "member": fil.name.indexOf('vendorId') > 0 ? qryDim + ".vendorId" : fil.name,
                            "operator": fil.operator ? fil.operator : "equals",
                            "values": fil.values
                        })
                    }
                }
                else {
                    setDrilldownFilter1ForMultipleQuery(fil, widget)
                }
            })

        })
    }
    return def
}

const setDrilldownFilter1ForMultipleQuery = (fil, widget) => {
    let i = 0;
    widget.vizState.query.forEach((query) => {
        if (!(widget.vizOptions.queryType === "allCategories" && fil.name === widget.vizOptions.category && i == 0)) {
            if (!_.find(query["filters"], { member: fil.name })) {
                let qryDim = query["measures"][0].substring(0, query["measures"][0].indexOf('.'))
                query["filters"].push({
                    "member": fil.name.indexOf('vendorId') > 0 ? qryDim + ".vendorId" : fil.name,
                    "operator": fil.operator ? fil.operator : "equals",
                    "values": fil.values
                })
            }
        }
        i++;
    })
}

const convertRStoSubGraph = (resultSet, tableRow, colors, type, vizOptions) => {
    let range = {};
    let categories = new Set();
    let i = 0;
    let totVal = 0;
    let totLen = 0;
    const filteredResultSet = _.filter(resultSet.tablePivot(), { ...tableRow })
    filteredResultSet?.forEach((item) => {
        if (vizOptions && vizOptions.pivot) {
            categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: item[vizOptions.pivot.y],
                    color: colors[i],
                    type: type,
                    data: [],
                };
                i++
            }
            range[item[vizOptions.pivot.y]].data.push(
                parseInt(item[vizOptions.pivot.measure])
            );
        } else {
            if (vizOptions && vizOptions.series) {
                if (vizOptions.categoryAll)
                    categories = item[vizOptions.categoryAll].split(",");
                else if (vizOptions.type === 'quarters')
                    categories = ['Q1', 'Q2', 'Q3', 'Q4'];
                else
                    categories.add(item[vizOptions.category]);
                vizOptions.series.map((col) => {
                    if (!range[col.value]) {
                        range[col.value] = {
                            name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                            color: colors[i],
                            type: type,
                            budgetddata: (vizOptions.type === 'variance') ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [],
                            costdata: (vizOptions.type === 'variance') ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [],
                            data: vizOptions.type === 'quarters' ? [0, 0, 0, 0] : [],
                            cc: [],
                            cb: [],
                            zoneAxis: 'x',
                            zones: (vizOptions.type === 'variance') ?
                                [{ "color": "#435cc8", "value": 0, }, { "color": '#435cc8', "value": 1, }, { "color": '#435cc8', "value": 2, },
                                { "color": "#435cc8", "value": 3, }, { "color": '#435cc8', "value": 4, }, { "color": '#435cc8', "value": 5, },
                                { "color": "#435cc8", "value": 6, }, { "color": '#435cc8', "value": 7, }, { "color": '#435cc8', "value": 8, },
                                { "color": "#435cc8", "value": 9, }, { "color": '#435cc8', "value": 10, }, { "color": '#435cc8', "value": 11, }] : '',
                        };
                        i++
                    }

                    if (parseInt(item[col.value]) !== 0) {
                        range[col.value].budgetddata[categories.indexOf(item[vizOptions.category])] = parseInt(item[vizOptions.fillValuesColumn] ? item[vizOptions.fillValuesColumn] : 0)
                        range[col.value].costdata[categories.indexOf(item[vizOptions.category])] = parseInt(item[vizOptions.predictColumn] ? item[vizOptions.predictColumn] : 0)
                        range[col.value].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value]) ? parseInt(item[col.value]) : 0
                        totLen = totLen + 1
                        totVal = totVal + range[col.value].data[categories.indexOf(item[vizOptions.category])]
                    }
                })
            }
        }
    });
    if (totLen > 0 && vizOptions.type === 'variance') {
        let tCost = 0
        let tBudget = 0
        let vp
        for (i = 0; i < 12; i++) {
            //getting the avg cost/budget of previous months
            let ct = i == 0 ? [range[vizOptions.series[0].value].costdata[i]] : range[vizOptions.series[0].value].costdata.slice(0, i)
            let bt = i == 0 ? [range[vizOptions.series[0].value].budgetddata[i]] : range[vizOptions.series[0].value].budgetddata.slice(0, i)
            let ctfilterd = ct.filter(item => item !== 0)
            let btfilterd = bt.filter(item => item !== 0)
            let avgcost = ctfilterd.length !== 0 ? Math.round(ct.reduce((a, b) => a + b, 0) / ctfilterd.length) : 0
            let avgbudget = btfilterd.length !== 0 ? Math.round(bt.reduce((a, b) => a + b, 0) / btfilterd.length) : 0
            //zone color selection
            let zclr = range[vizOptions.series[0].value].costdata[i] === 0 ? 0 : range[vizOptions.series[0].value].budgetddata[i] === 0 ? 0 : 1
            //taking the monthly cost and budget avg monthly cost/budget will be taken if it is zero
            let cost = range[vizOptions.series[0].value].costdata[i] === 0 ? avgcost : range[vizOptions.series[0].value].costdata[i]
            // updating the monthly data zero fields
            range[vizOptions.series[0].value].costdata[i] = cost
            let budget = range[vizOptions.series[0].value].budgetddata[i] === 0 ? avgbudget : range[vizOptions.series[0].value].budgetddata[i]
            // updating the monthly data zero fields
            range[vizOptions.series[0].value].budgetddata[i] = budget
            // Finding the cumilative monthly cost and budget
            tCost = tCost + cost
            tBudget = tBudget + budget
            //Finding the cumilative monthly variance
            vp = tCost - tBudget
            //updainge the cumilative monthly fileds
            range[vizOptions.series[0].value].data[i] = vp
            range[vizOptions.series[0].value].cc[i] = tCost
            range[vizOptions.series[0].value].cb[i] = tBudget
            //color zone setting
            range[vizOptions.series[0].value].zones[i].color = zclr == 0 ? '#cbd2eb' : '#435cc8';
            range[vizOptions.series[0].value].zones[i].value = i
        }

    }
    return { range, categories }
}

const convertRStoTimelineData = (resultSet, colors, vizOptions) => {
    let range = {};
    let categories = new Set();
    let legendColors = []
    let i = 0;
    if (!resultSet || resultSet.length === 0) {
        return [];
    }
    resultSet.tablePivot().map((item) => {
        if (vizOptions && vizOptions.series) {
            categories.add(item[vizOptions.category]);
            vizOptions.series.map((col) => {
                if (!range[col.value]) {
                    range[col.value] = {
                        name: item[col.name],
                        label: item[col.label],
                        description: item[col.description],
                        color: col?.color ? col.color : colors[i],
                        data: [],
                    };
                    i++
                }
            })
        }
    });
    return { range, categories, legendColors }
}

const convertRStoGraph = (resultSet, colors, type, vizOptions, metricLegend, target) => {
    let range = {};
    let categories = new Set();
    let legendColors = []
    let i = 0;

    resultSet.tablePivot().forEach((item) => {
        if (vizOptions && vizOptions.pivot) {
            if (vizOptions.type === "fullyear")
                categories = item[vizOptions.categoryAll].split(",");
            else
                categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: vizOptions.xAxisTitleText || item[vizOptions.pivot.y],
                    color: colors[i],
                    type: type,
                    data: vizOptions.type === "fullyear" ? vizOptions.straightLineView ? Array(12).fill(null): [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [],
                };
                i++
            }

            if (vizOptions.type === "fullyear") {
                if (parseInt(item[vizOptions.pivot.measure]) !== 0) {
                    range[item[vizOptions.pivot.y]].data[categories.indexOf(item[vizOptions.category])] = vizOptions.percentageFormat ? parseFloat(item[vizOptions.pivot.measure]) : parseInt(item[vizOptions.pivot.measure])
                }
            }
            else {
                range[item[vizOptions.pivot.y]].data.push(
                    parseInt(item[vizOptions.pivot.measure])
                );
            }
        } else {
            if (vizOptions && vizOptions.series) {
                categories.add(item[vizOptions.category]);
                vizOptions.series.map((col) => {
                    if (!range[col.value]) {
                        range[col.value] = {
                            name: (metricLegend && col.metricLegend) ? metricLegend : col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                            color: col?.color ? col.color : colors[i],
                            type: col.type ? col.type : type,
                            data: [],
                        };
                        i++
                        if (col.axis === 'primary') {
                            range[col.value].yAxis = 1;
                            range[col.value].metric = true
                        }
                        // if(col.targetCustom) {
                        //     range[col.value].dashStyle = 'shortdot',
                        //     range[col.value].legendActive = true
                        // }
                    }
                    col.metricLegend ? range[col.value].data.push(formatInteger(item[col.value])) : (col.defaultValue && item[col.value] === 0) ? range[col.value].data.push(formatInteger(target)) : range[col.value].data.push(parseInt(item[col.value]))
                })
            }
        }
    })
    return { range, categories, legendColors }
}

const convertRStoGraph_CF = (resultSet, colors, type, vizOptions) => {
    let range = {};
    let categories = new Set();
    let i = 0;
    let lastIndex = 0;
    resultSet.forEach((item) => {

        if (vizOptions && vizOptions.pivot) {
            if (vizOptions.type === "fullyear")
                categories = item[vizOptions.categoryAll].split(",");
            else
                categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: item[vizOptions.pivot.y],
                    color: colors[i],
                    type: type,
                    data: vizOptions.type === "fullyear" ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [],
                };
                i++
            }

            if (vizOptions.type === "fullyear") {
                if (parseInt(item[vizOptions.pivot.measure]) !== 0) {
                    range[item[vizOptions.pivot.y]].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[vizOptions.pivot.measure])
                }
            }
            else {
                range[item[vizOptions.pivot.y]].data.push(
                    parseInt(item[vizOptions.pivot.measure])
                );
            }
        } else {
            if (vizOptions && vizOptions.series) {
                if (vizOptions.type === "fullyear") {
                    categories = item[vizOptions.categoryAll].split(",");
                }
                else {
                    categories.add(item[vizOptions.category]);
                }
                vizOptions.series.map((col) => {
                    if (!range[col.value]) {
                        range[col.value] = {
                            name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                            color: colors[i],
                            type: type,
                            data: vizOptions.type === "fullyear" ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [],
                            zones: [{ "dashStyle": "", "value": 0, }, { "dashStyle": '', "value": 1, }, { "dashStyle": '', "value": 2, },
                            { "dashStyle": "", "value": 3, }, { "dashStyle": '', "value": 4, }, { "dashStyle": '', "value": 5, },
                            { "dashStyle": "", "value": 6, }, { "dashStyle": '', "value": 7, }, { "dashStyle": '', "value": 8, },
                            { "dashStyle": "", "value": 9, }, { "dashStyle": '', "value": 10, }, { "dashStyle": '', "value": 11, }],
                            pointStart: 0,
                            zoneAxis: 'x',
                        };
                        i++
                    }

                    if (vizOptions.type === "fullyear") {
                        if (parseInt(item[col.value]) !== 0)
                            range[col.value].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                    }
                    else
                        range[col.value].data.push(parseInt(item[col.value]));
                })
            }
        }
    });
    if (vizOptions.compare && range[vizOptions.compare]) {
        var arr = range[vizOptions.compare]?.data;
        for (let j = arr?.length - 1; j >= 0; j--) {
            if (arr[j] > 0) {
                lastIndex = j;
                break;
            }
        }
    }
    if (vizOptions.forecast && range[vizOptions.forecast]) {
        for (i = lastIndex + 1; i < 12; i++) {
            range[vizOptions.forecast].zones[i].dashStyle = 'shortdot';
            range[vizOptions.forecast].zones[i].value = i
        }
    }

    return { range, categories }
}


const convertRStoMultiGraph = (resultSet, colors, type, vizOptions) => {
    let range = {};
    let monthlyFilter = []
    let categories = new Set();
    let i = 0;
    resultSet.tablePivot().forEach((item) => {

        if (vizOptions && vizOptions.pivot) {
            if (vizOptions.type === "fullyear")
                categories = item[vizOptions.categoryAll].split(",");
            else
                categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: item[vizOptions.pivot.y],
                    color: colors[i],
                    type: type,
                    data: vizOptions.type === "fullyear" ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [],
                };
                i++
            }

            if (vizOptions.type === "fullyear") {
                if (parseInt(item[vizOptions.pivot.measure]) !== 0) {
                    range[item[vizOptions.pivot.y]].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[vizOptions.pivot.measure])
                }
            }
            else {
                range[item[vizOptions.pivot.y]].data.push(
                    parseInt(item[vizOptions.pivot.measure])
                );
            }
        } else {
            if (vizOptions && vizOptions.series) {
                categories.add(item[vizOptions.category]);
            }
        }
    })
    if (vizOptions && categories.size > 0) {
        categories.forEach(element => {
            monthlyFilter.push(_.filter(resultSet.tablePivot(), { [vizOptions.category]: element }))
        })
        monthlyFilter.forEach(element => {
            element.map(col => {
                if (!range[col[vizOptions.series.name]]) {
                    range[col[vizOptions.series.name]] = {
                        name: col[vizOptions.series.name] ? col[vizOptions.series.name] : '',
                        color: colors[i],
                        type: type,
                        data: [],
                    };
                    i++
                }
                range[col[vizOptions.series.name]].data.push(parseInt(col[vizOptions.series.value]));
            })
        })
    }
    return { range, categories }
}

const convertRStoGraphYearly = (resultSet, colors, type, vizOptions) => {
    let range = {};
    let categories = new Set();
    let i = 0;

    resultSet.tablePivot().forEach((item) => {
        if (vizOptions && vizOptions.pivot) {
            categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: item[vizOptions.pivot.y],
                    color: colors[i],
                    type: type,
                    data: [],
                };
                i++
            }
            range[item[vizOptions.pivot.y]].data.push(
                parseInt(item[vizOptions.pivot.measure])
            );
        } else {
            if (vizOptions && vizOptions.series) {

                categories = item[vizOptions.categoryAll].split(",");
                vizOptions.series.map((col) => {
                    if (!range[col.value]) {
                        range[col.value] = {
                            name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                            color: colors[i],
                            type: type,
                            // data: ['', '', '', '', '', '', '', '', '', '', '', ''],
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        };
                        i++
                    }

                    if (parseInt(item[col.value]) !== 0) {
                        range[col.value].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                    }
                })
            }
        }
    });

    return { range, categories }
}

const convertRStoGraphYearly_CF = (resultSet, colors, type, vizOptions) => {
    let range = {};
    let categories = new Set();
    let i = 0;

    resultSet.tablePivot().forEach((item) => {
        if (vizOptions && vizOptions.pivot) {
            categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: item[vizOptions.pivot.y],
                    color: colors[i],
                    type: type,
                    data: [],
                };
                i++
            }
            range[item[vizOptions.pivot.y]].data.push(
                parseInt(item[vizOptions.pivot.measure])
            );
        } else {
            if (vizOptions && vizOptions.series) {

                categories = item[vizOptions.categoryAll].split(",");
                vizOptions.series.map((col) => {
                    if (!range[col.value]) {
                        range[col.value] = {
                            name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                            color: colors[i],
                            type: type,
                            // data: ['', '', '', '', '', '', '', '', '', '', '', ''],
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        };
                        i++
                    }

                    if (parseInt(item[col.value]) !== 0) {
                        range[col.value].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                    }
                })
            }
        }
    });

    return { range, categories }
}

const convertRStoGraphYearlyStackedAreaSpline = (resultSet, colors, type, vizOptions) => {
    let range = {};
    let categories = new Set();
    let i = 0;
    let nam = '';

    resultSet.tablePivot().forEach((item) => {
        if (vizOptions && vizOptions.series) {
            categories = item[vizOptions.categoryAll].split(",");
            vizOptions.series.map((col) => {
                if (col.useNameString)
                    nam = col.name
                else
                    nam = item[col.name]
                if (!range[nam]) {
                    range[nam] = {
                        name: nam ? nam : '',
                        color: colors[i++],
                        type: type,
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    };
                }

                if (parseInt(item[col.value]) !== 0) {
                    range[nam].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                }
            })
        }
    });
    return { range, categories }
}

const convertRStoGraphYearlyLine = (resultSet, colors, vizOptions) => {
    let range = {};
    let categories = new Set();
    let totLen = 0;
    // let last0Index = -1;
    // let last1Index = -1;
    let i = 0;

    resultSet.tablePivot().forEach((item) => {

        if (vizOptions && vizOptions.pivot) {
            categories.add(item[vizOptions.pivot.x]);
            if (!range[item[vizOptions.pivot.y]]) {
                range[item[vizOptions.pivot.y]] = {
                    name: item[vizOptions.pivot.y],
                    color: colors[i],
                    type: "column",
                    yAxis: 1,
                    data: [],
                };
                i++
            }

            range[item[vizOptions.pivot.y]].data.push(
                parseInt(item[vizOptions.pivot.measure])
            );
        } else {
            if (vizOptions && vizOptions.series) {
                categories = item[vizOptions.categoryAll].split(",");
                vizOptions.series.map((col) => {
                    if (!range[col.value]) {
                        range[col.value] = {
                            name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                            color: colors[i],
                            type: "column",
                            yAxis: 1,
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        };
                        i++
                    }

                    if (parseInt(item[col.value]) !== 0) {
                        range[col.value].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                        totLen = totLen + 1;
                        // if (col.value === vizOptions.series[0].value) 
                        //     last0Index = categories.indexOf(item[vizOptions.category])

                        // if (col.value === vizOptions.series[1].value) 
                        //     last1Index = categories.indexOf(item[vizOptions.category])

                    }
                })
            }
        }
    });

    resultSet.tablePivot().forEach((item) => {
        if (vizOptions && vizOptions.pivot) {
            if (!range[item[vizOptions.pivot.y] + " YTD"]) {
                range[item[vizOptions.pivot.y] + " YTD"] = {
                    name: item[vizOptions.pivot.y] + " YTD",
                    color: colors[i],
                    type: "spline",
                    data: [],
                };
                i++
            }

            range[item[vizOptions.pivot.y] + " YTD"].data.push(
                (range[item[vizOptions.pivot.y] + " YTD"].data.length === 0) ?
                    parseInt(item[vizOptions.pivot.measure])
                    :
                    range[item[vizOptions.pivot.y] + " YTD"].data[range[item[vizOptions.pivot.y] + " YTD"].data.length - 1] + parseInt(item[vizOptions.pivot.measure])
            );

        } else {
            if (vizOptions && vizOptions.series) {
                categories = item[vizOptions.categoryAll].split(",");
                vizOptions.series.map((col) => {
                    if (!range[col.value + " YTD"]) {
                        range[col.value + " YTD"] = {
                            name: col.useNameString ? col.name + " YTD" : item[col.name] ? item[col.name] + " YTD" : '',
                            color: colors[i],
                            type: "spline",
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        };
                        i++
                    }

                    if (parseInt(item[col.value]) !== 0) {
                        (categories.indexOf(item[vizOptions.category]) === 0) ?
                            range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                            :
                            range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category])] = range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category]) - 1] + parseInt(item[col.value])
                    }
                })
            }
        }
    });

    if (range && Object.keys(range).length)
        for (i = 0; i < 12; i++) {
            range[vizOptions.series[0]?.value + " YTD"].data[i] = (i === 0 ? range[vizOptions.series[0].value].data[i] : range[vizOptions.series[0].value + " YTD"].data[i - 1] + range[vizOptions.series[0].value].data[i]);
            range[vizOptions.series[1]?.value + " YTD"].data[i] = (i === 0 ? range[vizOptions.series[1].value].data[i] : range[vizOptions.series[1].value + " YTD"].data[i - 1] + range[vizOptions.series[1].value].data[i]);
        }

    return { range, categories }

}

const convertRStoGraphYearlyPredicted = (resultSet, colors, vizOptions) => {
    let range = {};
    let categories = [];
    let i = 0;
    let avgVal = 0;
    let totVal = 0;
    let totLen = 0;
    let lastIndex = -1;
    let lastFillIndex = -1;

    if (resultSet.tablePivot().length > 0) {
        resultSet.tablePivot().forEach((item) => {

            if (vizOptions && vizOptions.pivot) {
                categories.add(item[vizOptions.pivot.x]);
                if (!range[item[vizOptions.pivot.y]]) {
                    range[item[vizOptions.pivot.y]] = {
                        name: item[vizOptions.pivot.y],
                        color: colors[i],
                        type: "column",
                        yAxis: 1,
                        data: [],
                    };
                    i++
                }

                range[item[vizOptions.pivot.y]].data.push(
                    parseInt(item[vizOptions.pivot.measure])
                );
            } else {
                if (vizOptions && vizOptions.series) {
                    if (categories.length === 0)
                        categories = item[vizOptions.categoryAll].split(",");
                    vizOptions.series.map((col) => {
                        if (!range[col.value]) {
                            range[col.value] = {
                                name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                                color: colors[i],
                                type: "column",
                                yAxis: 1,
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            };
                            i++
                        }

                        if (parseInt(item[col.value]) !== 0) {
                            range[col.value].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                            if (col.value === vizOptions.predictColumn) {
                                totVal = totVal + parseInt(item[col.value]);
                                totLen = totLen + 1;
                                lastIndex = categories.indexOf(item[vizOptions.category])
                            }
                            if (col.value === vizOptions.fillValuesColumn)
                                lastFillIndex = categories.indexOf(item[vizOptions.category])
                        }

                    })
                }
            }
        });

        resultSet.tablePivot().forEach((item) => {
            if (vizOptions && vizOptions.pivot) {
                if (!range[item[vizOptions.pivot.y] + " YTD"]) {
                    range[item[vizOptions.pivot.y] + " YTD"] = {
                        name: item[vizOptions.pivot.y] + " YTD",
                        color: colors[i],
                        type: "spline",
                        data: [],
                    };
                    i++
                }

                range[item[vizOptions.pivot.y] + " YTD"].data.push(
                    (range[item[vizOptions.pivot.y] + " YTD"].data.length === 0) ?
                        parseInt(item[vizOptions.pivot.measure])
                        :
                        range[item[vizOptions.pivot.y] + " YTD"].data[range[item[vizOptions.pivot.y] + " YTD"].data.length - 1] + parseInt(item[vizOptions.pivot.measure])
                );

            } else {
                if (vizOptions && vizOptions.series) {
                    if (categories.length === 0)
                        categories = item[vizOptions.categoryAll].split(",");
                    vizOptions.series.map((col) => {
                        if (!range[col.value + " YTD"]) {
                            range[col.value + " YTD"] = {
                                name: col.useNameString ? col.name + " YTD" : item[col.name] ? item[col.name] + " YTD" : '',
                                color: colors[i],
                                type: "spline",
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                zones: [{ "dashStyle": "", "value": 0, }, { "dashStyle": '', "value": 1, }, { "dashStyle": '', "value": 2, },
                                { "dashStyle": "", "value": 3, }, { "dashStyle": '', "value": 4, }, { "dashStyle": '', "value": 5, },
                                { "dashStyle": "", "value": 6, }, { "dashStyle": '', "value": 7, }, { "dashStyle": '', "value": 8, },
                                { "dashStyle": "", "value": 9, }, { "dashStyle": '', "value": 10, }, { "dashStyle": '', "value": 11, }],
                                pointStart: 0,
                                zoneAxis: 'x',
                            };
                            i++
                        }

                        if (parseInt(item[col.value]) !== 0) {
                            (categories.indexOf(item[vizOptions.category]) === 0) ?
                                range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                                :
                                range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category])] = range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category]) - 1] + parseInt(item[col.value])

                        }
                    })
                }
            }
        });

        if (range && Object.keys(range).length)
            for (i = 0; i < 12; i++) {
                range[vizOptions.predictColumn + " YTD"].data[i] = (i === 0 ? range[vizOptions.predictColumn].data[i] : range[vizOptions.predictColumn + " YTD"].data[i - 1] + range[vizOptions.predictColumn].data[i]);
                range[vizOptions.fillValuesColumn + " YTD"].data[i] = (i === 0 ? range[vizOptions.fillValuesColumn].data[i] : range[vizOptions.fillValuesColumn + " YTD"].data[i - 1] + range[vizOptions.fillValuesColumn].data[i]);
            }

        if (totLen > 0) {
            avgVal = Math.round(totVal / totLen);

            for (i = lastIndex + 1; i < 12; i++) {
                range[vizOptions.predictColumn + " YTD"].data[i] =
                    ((lastIndex + 1) === 0) ?
                        avgVal
                        :
                        range[vizOptions.predictColumn + " YTD"].data[i - 1] + avgVal;

                range[vizOptions.predictColumn + " YTD"].zones[i].dashStyle = 'shortdot';
                range[vizOptions.predictColumn + " YTD"].zones[i].value = i
            }
            for (i = lastFillIndex + 1; i < 12; i++) {
                range[vizOptions.fillValuesColumn + " YTD"].data[i] =
                    ((lastFillIndex + 1) === 0) ?
                        0
                        :
                        range[vizOptions.fillValuesColumn + " YTD"].data[i - 1];
            }
        }
    }

    return { range, categories }

}

const convertRStoGraphYearlyPredicted_CF = (resultSet, colors, vizOptions) => {
    let range = {};
    let categories = [];
    let i = 0;
    let avgVal = 0;
    let totVal = 0;
    let totLen = 0;
    let lastIndex = -1;
    let lastFillIndex = -1;

    if (resultSet.tablePivot().length > 0) {
        resultSet.tablePivot().forEach((item) => {

            if (vizOptions && vizOptions.pivot) {
                categories.add(item[vizOptions.pivot.x]);
                if (!range[item[vizOptions.pivot.y]]) {
                    range[item[vizOptions.pivot.y]] = {
                        name: item[vizOptions.pivot.y],
                        color: colors[i],
                        type: "column",
                        yAxis: 1,
                        data: [],
                    };
                    i++
                }

                range[item[vizOptions.pivot.y]].data.push(
                    parseInt(item[vizOptions.pivot.measure])
                );
            } else {
                if (vizOptions && vizOptions.series) {
                    if (categories.length === 0)
                        categories = item[vizOptions.categoryAll].split(",");
                    vizOptions.series.map((col) => {
                        if (!range[col.value]) {
                            range[col.value] = {
                                name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                                color: colors[i],
                                type: "column",
                                yAxis: 1,
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            };
                            i++
                        }

                        if (parseInt(item[col.value]) !== 0) {
                            range[col.value].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                            if (col.value === vizOptions.predictColumn) {
                                totVal = totVal + parseInt(item[col.value]);
                                totLen = totLen + 1;
                                lastIndex = categories.indexOf(item[vizOptions.category])
                            }
                            if (col.value === vizOptions.fillValuesColumn)
                                lastFillIndex = categories.indexOf(item[vizOptions.category])
                        }

                    })
                }
            }
        });

        resultSet.tablePivot().forEach((item) => {
            if (vizOptions && vizOptions.pivot) {
                if (!range[item[vizOptions.pivot.y] + " YTD"]) {
                    range[item[vizOptions.pivot.y] + " YTD"] = {
                        name: item[vizOptions.pivot.y] + " YTD",
                        color: colors[i],
                        type: "spline",
                        data: [],
                    };
                    i++
                }

                range[item[vizOptions.pivot.y] + " YTD"].data.push(
                    (range[item[vizOptions.pivot.y] + " YTD"].data.length === 0) ?
                        parseInt(item[vizOptions.pivot.measure])
                        :
                        range[item[vizOptions.pivot.y] + " YTD"].data[range[item[vizOptions.pivot.y] + " YTD"].data.length - 1] + parseInt(item[vizOptions.pivot.measure])
                );

            } else {
                if (vizOptions && vizOptions.series) {
                    if (categories.length === 0)
                        categories = item[vizOptions.categoryAll].split(",");
                    vizOptions.series.map((col) => {
                        if (!range[col.value + " YTD"]) {
                            range[col.value + " YTD"] = {
                                name: col.useNameString ? col.name + " YTD" : item[col.name] ? item[col.name] + " YTD" : '',
                                color: colors[i],
                                type: "spline",
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                                zones: [{ "dashStyle": "", "value": 0, }, { "dashStyle": '', "value": 1, }, { "dashStyle": '', "value": 2, },
                                { "dashStyle": "", "value": 3, }, { "dashStyle": '', "value": 4, }, { "dashStyle": '', "value": 5, },
                                { "dashStyle": "", "value": 6, }, { "dashStyle": '', "value": 7, }, { "dashStyle": '', "value": 8, },
                                { "dashStyle": "", "value": 9, }, { "dashStyle": '', "value": 10, }, { "dashStyle": '', "value": 11, }],
                                pointStart: 0,
                                zoneAxis: 'x',
                            };
                            i++
                        }

                        if (parseInt(item[col.value]) !== 0) {
                            (categories.indexOf(item[vizOptions.category]) === 0) ?
                                range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category])] = parseInt(item[col.value])
                                :
                                range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category])] = range[col.value + " YTD"].data[categories.indexOf(item[vizOptions.category]) - 1] + parseInt(item[col.value])

                        }
                    })
                }
            }
        });

        if (range && Object.keys(range).length)
            for (i = 0; i < 12; i++) {
                range[vizOptions.predictColumn + " YTD"].data[i] = (i === 0 ? range[vizOptions.predictColumn].data[i] : range[vizOptions.predictColumn + " YTD"].data[i - 1] + range[vizOptions.predictColumn].data[i]);
                range[vizOptions.fillValuesColumn + " YTD"].data[i] = (i === 0 ? range[vizOptions.fillValuesColumn].data[i] : range[vizOptions.fillValuesColumn + " YTD"].data[i - 1] + range[vizOptions.fillValuesColumn].data[i]);
            }

        if (totLen > 0) {
            avgVal = Math.round(totVal / totLen);

            for (i = lastIndex + 1; i < 12; i++) {
                range[vizOptions.predictColumn + " YTD"].data[i] =
                    ((lastIndex + 1) === 0) ?
                        avgVal
                        :
                        range[vizOptions.predictColumn + " YTD"].data[i - 1] + avgVal;

                range[vizOptions.predictColumn + " YTD"].zones[i].dashStyle = 'shortdot';
                range[vizOptions.predictColumn + " YTD"].zones[i].value = i
            }
            for (i = lastFillIndex + 1; i < 12; i++) {
                range[vizOptions.fillValuesColumn + " YTD"].data[i] =
                    ((lastFillIndex + 1) === 0) ?
                        0
                        :
                        range[vizOptions.fillValuesColumn + " YTD"].data[i - 1];
            }
        }
    }

    return { range, categories }

}


const getName = ((name, vizOptions) => {
    let names = [];
    if (vizOptions.groupItems) {
        if (vizOptions.groupCategory === 'costpool')
            names = [
                { name: "Labor", value: "Internal Labor - " },
                { name: "Labor", value: "External Labor - " },
                { name: "Labor", value: "Outside Services - Consulting" },
                { name: "Services", value: "Outside Services - Managed Service Provider" },
                { name: "Services", value: "Outside Services - Cloud Service Provider" },
                { name: "Services", value: "Internal Services -  By Shared Service" },
                { name: "Hardware", value: "Hardware - " },
                { name: "Software", value: "Software - " }
            ];
        if (vizOptions.groupCategory === 'labor')
            names = [
                { name: "Internal Labor", value: "Internal Labor" },
                { name: "External Labor", value: "External Labor" },
                { name: "Consulting", value: "Outside Services" },
            ];
    }
    let catName = '';
    names.map((nam) => {
        if (name.includes(nam.value)) {
            catName = nam.name;
            return
        }
    })
    return vizOptions.groupItems ? catName || 'Others' : name;
})

const toNumber = (value) => isNaN(Number(value)) ? 0 : Number(value);
const toInt = (value) => isNaN(parseInt(value)) ? 0 : parseInt(value);
const toFloat = (value) => isNaN(parseFloat(value)) ? 0 : parseFloat(value);
const i18nMsg = (msgObj, key) => msgObj && msgObj[key] ? msgObj[key] : key;

const downloadCPRules = async (yearFilter, monthFilter, yearFilterName, monthFilterName) => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/costPoolMapping/${yearFilter}/${monthFilter}?download=${true}`)
    if (!err) {
        let newData = data.map(item => {
            return {
                "Account Code": item["account.code"],
                "Account Description": item["account.description"],
                "Expense Type": item["expenseType.name"],
                "Cost Center Code": item["costCentre.code"],
                "Cost Center Description": item["costCentre.description"],
                "Vendor Code": item["vendor.code"],
                "Vendor Name": item["vendor.name"],
                "Cost Pool": item["costPool.name"],
                "Sub Cost Pool": item["subCostPool.name"],
            }
        });
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(newData), "Sheet1");
        XLSX.writeFile(wb, 'CostPoolMapping_' + monthFilter + '_' + yearFilter + '.xlsx');
        await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Cost Pool Mapping for ${monthFilterName} - ${yearFilterName}` })
    } else {
        console.error(err)
        // showAlert(err)
    }
}

function getYear(years, yearFilterName, monthFilter) {
    const startDate = _.find(years, { name: yearFilterName })?.startDate;
    const yearSplit = yearFilterName?.split('-').length > 1 ? yearFilterName.split('-').map(year => {
        if (Number(year) < 100) {
            const currCentury = Number(Math.round(moment(Date.now()).year() / 100))
            year = (currCentury * 100) + Number(year)
            return Number(year)
        } else {
            return Number(year)
        }
    }) : Number(yearFilterName.match(/\d+/g))
    if (Array.isArray(yearSplit)) {
        if (monthFilter >= Number(moment(startDate || '').month() + 1)) {
            return yearSplit[0]
        } else {
            return yearSplit[1]
        }
    } else {
        const dateFormat1 = Number((String(yearSplit) + (monthFilter < 10 ? `0` + monthFilter : monthFilter)))
        const dateFormat2 = moment(startDate || '').year() * 100 + moment(startDate || '').month() + 1;
        if (dateFormat1 >= dateFormat2) {
            return yearSplit
        } else {
            return yearSplit + 1
        }
    }
}

const downloadChargeBack = async (years, data, status, yearFilterName, monthFilter, monthFilterName) => {

    if (data?.length > 0) {
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(data), "Sheet1");
        XLSX.writeFile(wb, `Chargeback_${status}_${getYear(years, yearFilterName, monthFilter)}_${monthFilter < 10 ? `0${monthFilter}` : monthFilter}.csv`);
        await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Chargeback for ${yearFilterName} - ${monthFilterName}` });
    }
}

const getChargeBack = async () => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/chargeBack`);
    if (!err) {
        return data
    } else {
        console.error(err)
    }
}

const getChargeBackMonthly = async (yearNameId) => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/chargeBack/${yearNameId}`);
    if (!err) {
        return data
    } else {
        console.error(err)
    }
}

const getChargeBackByMonth = async (yearNameId, monthNameId) => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/chargeBack/${yearNameId}/${monthNameId}`);
    if (!err) {
        return data
    } else {
       console.error(err)
    }
}

const getPublished = async () => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/publish`);
    if (!err) {
        return data
    } else {
        console.error(err)
    }
}

const getPublishedMonthly = async (yearNameId) => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/publish/${yearNameId}`);
    if (!err) {
        return data
    } else {
        console.error(err)
    }
}

const getPublishedByMonth = async (yearNameId, monthNameId) => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/publish/${yearNameId}/${monthNameId}`);
    if (!err) {
        return data
    } else {
        (err)
    }
}

const downloadTRRules = async (yearFilter, monthFilter, yearFilterName, monthFilterName) => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}?download=${true}`)
    if (!err) {
        let newData = data.map(item => {
            return {
                "Cost Center Code": item["costCentre.code"],
                "Cost Center Description": item["costCentre.description"],
                "Expense Type": item["expenseType.name"],
                "Cost Pool": item["costPool.name"],
                "Sub Cost Pool": item["subCostPool.name"],
                "Account Code": item["account.code"],
                "Account Description": item["account.description"],
                "Vendor Code": item["vendor.code"],
                "Vendor Name": item["vendor.name"],
                "Tower": item["tower.name"],
                "Sub Tower": item["subTower.name"],
                "Portion": item["portion"],
                "Rule Name": item["ruleName"],
                "Tier": item["tier"],
                "Condition": item["formattedCond"],
                "Destination": item["destinationTable"],
                "Weight": item["weight"],
                "Serial Number": item["srlNo"]
            }
        });
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(newData), "Sheet1");
        XLSX.writeFile(wb, 'TowerMapping_' + monthFilter + '_' + yearFilter + '.xlsx');
        await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Tower Mapping for ${monthFilterName} - ${yearFilterName}` })
    } else {
        console.error(err)
        // showAlert(err)
    }
}

const downloadSolutionMapping = async (yearFilter, monthFilter, yearFilterName, monthFilterName)=> {
    let [err, data] = await fetchRequest.get(`/api/dataflow/solution/${yearFilter}/${monthFilter}`)
    if(!err) {
        let newData = data.map(item=> {
            return {
              "Asset Type": item["assetType"],
              "Asset Code": item["capabilityCode"],
              "Asset Name": item["applicationName"],
              "Offering Code": item["offeringCode"],
              "Offering Name": item["offeringName"],
              "Source": item['source'] ? "Rule": "File",
              "Portion": item["rPortion"],
              "Amount": item["spend"]

            }
        });
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(newData), "Sheet1");
        XLSX.writeFile(wb, 'SolutionMapping_' + monthFilter + '_' + yearFilter + '.xlsx');
        await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Solution Mapping for ${yearFilterName} - ${monthFilterName}` })
       
    }
    else {
        console.error(err)
    }
}

const downloadSolutionRules = async (yearFilter, monthFilter, yearFilterName, monthFilterName)=> {
    let [err, data] = await fetchRequest.post(`/api/solutionOfferingRules/list/${yearFilter}/${monthFilter}`)
    if(!err) {
        let newData = data.map(item => {
            return {
              "From Asset": item["fromAsset"],
              "Rule": item["rule"],
              "Solution Rule": item['toSolutionOfferingRule'],
              "Sequence": item["sequence"]
            }
          })
            const wb = XLSX.utils.book_new()
            const ws = XLSX.utils.json_to_sheet(newData)
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
            XLSX.writeFile(wb, 'SolutionRule_' + monthFilter + '_' + yearFilter + '.xlsx');
            await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded solution Rules for ${yearFilterName} - ${monthFilterName}` })
       
    }
    else {
        console.error(err)
    }
}

const downloadSRRules = async (yearFilter, monthFilter, yearFilterName, monthFilterName) => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/solutionMapping/${yearFilter}/${monthFilter}`)
    if (!err) {
        let newData = data.map(item => {
            return {
                "Cost Center Code": item["costCentre.code"],
                "Cost Center Description": item["costCentre.description"],
                "Expense Type": item["expenseType.name"],
                "Tower": item["tower.name"],
                "Sub Tower": item["subTower.name"],
                "Account Code": item["account.code"],
                "Account Description": item["account.description"],
                "Vendor Code": item["vendor.code"],
                "Vendor Name": item["vendor.name"],
                "Solution Type": item["solutionType.name"],
                "Solution Category": item["solutionCategory.name"],
                "Solution Name": item["solutionName.name"],
                "Portion": item["portion"],
                "Tier": item["tier"],
                "Condition": item["condition"],
                "Destination": item["destinationTable"],
                "Weight": item["weight"],
                "Serial Number": item["srlNo"]
            }
        });
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(newData), "Sheet1");
        XLSX.writeFile(wb, 'SolutionMapping_' + monthFilter + '_' + yearFilter + '.xlsx');
        await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Solution Rules for ${yearFilterName} - ${monthFilterName}` })
    } else {
        console.error(err)
        // showAlert(err)
    }
}
const downloadBURules = async (yearFilter, monthFilter, yearFilterName, monthFilterName) => {
    let [err, data] = await fetchRequest.get(`/api/dataflow/businessunitMapping/${yearFilter}/${monthFilter}`)
    if (!err) {
        let newData = data.map(item => {
            return {
                "Business Unit ID": item["businessUnitsCode"],
                "Business Unit Name": item["businessUnitsName"],
                "Offering ID": item["solutionOfferingsCode"],
                "Offering Name": item["solutionOfferingsName"],
                "Usage": item["usage"],
                "Rule Name": item["ruleName"],
                "Condition": item["con"],
                "Destination": item["destinationTable"],
                "Weight": item["weight"],
            }
        });
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(newData), "Sheet1");
        XLSX.writeFile(wb, 'BusinessUnitMapping_' + monthFilter + '_' + yearFilter + '.xlsx');
        await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded Business Unit Rules for ${yearFilterName} - ${monthFilterName}` })
    } else {
        console.error(err)
        // showAlert(err)
    }
}

const backgroundProcessCheck = async (monthFilter, yearFilter) => {
    try {
        const data = await fetchRequest.get('/api/processLogs/running');
        if (Array.isArray(data) && data.length > 0) {
            let bgData
            let msg = ['Attention', 'Data upload is temporarily disabled. Please wait for the running process to complete.', "/admin/background-processes", "View Running Processes"]
            if (monthFilter && yearFilter) {
                let bgDataYM = data[1].filter(d => d.monthNameId === monthFilter && d.yearNameId === yearFilter);
                let bgDataWYM = data[1].filter(d => !d.monthNameId && !d.yearNameId);
                bgData = bgDataYM.concat(bgDataWYM)
                bgData.length > 0 ? msg : msg = []

            } else {
                data[1].length > 0 ? msg : msg = []
            }
            return msg;
        }
        return [];
    } catch (err) {
        return [];
    }
}

const removeSessionFilter = (resultSet, vizOptions) => {
    let currentFilters = []
    let session = ["Months.month", "Years.year", "ExpenseTypes.name"]

    resultSet.map((f) => {
        if (!session.includes(f.member) || vizOptions.popupTo) {
            if (f.member !== undefined)
                return currentFilters.push({ name: f.member, operator: f.operator, values: f.values })
        }
    })
    return currentFilters
}

const removeSessionFilter_CF = (resultSet, vizOptions) => {
    let currentFilters = []
    let session = ["Months.month", "Years.year", "ExpenseTypes.name"]

    resultSet.map((f) => {
        if (!session.includes(f.member) || vizOptions.popupTo) {
            if (f.member !== undefined)
                return currentFilters.push({ name: f.member, operator: f.operator, values: f.values })
        }
    })
    return currentFilters
}

const ActiveStatusReverse = (rows) => {
    let newrow = rows.map((elem) => {
        elem.disabled ? elem.disabled == "Yes" ? elem.disabled = "No" : elem.disabled = "Yes" : elem
        return (elem)
    })
    return (newrow)
}

const Roleformat = (rows, masterDef) => {
    let fieldset = masterDef.fields.filter(elem => elem.type === "multiselect")[0].dataSource.data
    const fieldLabelMap = {};
    fieldset.forEach(item => {
        fieldLabelMap[item.value] = item.label;
    });
    let updatedRows = rows
        .filter(row => !row.roles?.split(',').includes('userAdmin'))
        .map(row => {
            const roles = row?.roles?.split(',');
            let roleLabels = roles?.map(item => fieldLabelMap[item] || item);
            if (roleLabels)
                return {
                    ...row,
                    roles: roleLabels.join(', ')
                };
        });
    return updatedRows;
}
const ConvertRstoGraphdaily = (resultSet, colors, type, vizOptions) => {
    let range = {};
    let categories = []
    let dataSet = []
    let dateValuSet = []
    let daysInMonth
    resultSet.tablePivot().forEach((item) => {
        let creatDateData = (month, year, item, value) => {
            let dateStr = item[vizOptions.category].toString();
            let day = parseInt(dateStr.substring(6, 8));
            function getDaysInMonth(year, month) {
                let lastDay = new Date(year, month, 0).getDate();
                return lastDay;
            }
            if (!daysInMonth)
                daysInMonth = getDaysInMonth(Number(year), Number(month));

            if (dataSet.length === 0)
                dataSet = new Array(daysInMonth).fill(0);

            for (let i = 1; i <= daysInMonth; i++) {
                if (categories.length < daysInMonth) {
                    categories.push(`Day ${i}`)
                }
                if (i === day) {
                    dataSet[i - 1] = dataSet[i - 1] + parseInt(item[value])
                    dateValuSet.push({ "label": `Day ${i}`, 'value': item[vizOptions.category] })
                }
            }
            return (dataSet)
        }
        if (vizOptions && vizOptions.series) {
            vizOptions.series.map((col) => {
                range[col.value] = {
                    name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                    color: colors[0],
                    type: type,
                    data: creatDateData(item[vizOptions.monthField], item[vizOptions.yearField], item, col.value),
                };
            })
        }
    });
    return { range, categories, dateValuSet }
}
const ConvertRstoGraphdailyStacked = (resultSet, colors, vizOptions) => {

    const pickColorNum = (value) => {
        let sum = 0;
        let value1 = value?.toUpperCase();
        for (let i = 0; i < value1.length; i++) {
            sum += value1[i]?.charCodeAt();
        }
        let num = Math.abs((sum + value1.length) % 36);
        return num
    }


    const providerColorSet = (subCategory, num, colors) => {
        let providerColor = '#000000'
        let providerName = providerNameVariations[subCategory?.toUpperCase()]
        if (providerName) {
            providerColor = colors[providerName][num]
        } else {
            let colorNum = pickColorNum(subCategory)
            providerColor = colorNum < 36 ? colors[colorNum][num] : providerColor
        }
        return providerColor
    }

    let range = [];
    let categories = []
    let daysInMonth
    let rangeSet = {}
    let dateValuSet = []

    resultSet.tablePivot().forEach((item) => {
        let creatDateData = (month, year) => {
            function getDaysInMonth(year, month) {
                let lastDay = new Date(year, month, 0).getDate();
                return lastDay;
            }
            if (!daysInMonth)
                daysInMonth = getDaysInMonth(Number(year), Number(month));
            return new Array(daysInMonth).fill(0);
        };

        if (vizOptions && vizOptions.series) {
            vizOptions.series.forEach((col) => {
                const subCategory = item[vizOptions.categorySub];
                if (!rangeSet[subCategory]) {
                    rangeSet[subCategory] = {
                        "name": subCategory,
                        "type": "column",
                        "color": providerColorSet(subCategory, 0, colors.providerColors),
                        "stack": item[col.name],
                        "showInLegend": vizOptions.providerview ? true : false,
                        "data": creatDateData(item[vizOptions.monthField], item[vizOptions.yearField])
                    };
                }
                const dateStr = item[vizOptions.category].toString();
                const day = parseInt(dateStr.substring(6, 8)) - 1;
                rangeSet[subCategory].data[day] += parseInt(item[col.value]);

                for (let i = 0; i <= daysInMonth; i++) {
                    if (categories.length < daysInMonth) {
                        categories.push(`Day ${i + 1}`);
                    }
                    if (i === day) {
                        dateValuSet.push({ "label": `Day ${i + 1}`, 'value': item[vizOptions.category], 'name': subCategory })
                    }

                }

            });
        }
    });
    range = Object.values(rangeSet) ? Object.values(rangeSet) : range
    if (range.length > 0) {
        range[0].showInLegend = true
    }

    return { range, categories, dateValuSet }
}

const optionsCreate = (item, options, newFormset) => {
    let closestMatch
    let idx = newFormset.findIndex(obj => item.parentField in obj);
    let opt = options.find(elem => elem.name === item.fieldName)
    if (idx !== -1) {
        if (newFormset[idx][item.parentField]) {
            let filterOptions = opt.options.filter(obj => obj.value.slice(0, 3) === newFormset[idx][item.parentField].slice(0, 3));
            if (filterOptions.length > 0) {
                let filtervealueset = newFormset[idx][item.parentField].split(' ')
                if (filtervealueset[0] !== filterOptions[0].value) {
                    closestMatch = filterOptions[0].value + ',' + filtervealueset[0] + ',' + filterOptions[0].value.slice(0, 3)
                }
                else {
                    closestMatch = filterOptions[0].value + ',' + filterOptions[0].value.slice(0, 3)
                }

            } else {
                let filtervealueset = newFormset[idx][item.parentField].split(' ')
                if (filtervealueset[0] !== newFormset[idx][item.parentField]) {
                    closestMatch = newFormset[idx][item.parentField] + ',' + filtervealueset[0] + ',' + newFormset[idx][item.parentField].slice(0, 3)
                } else {
                    closestMatch = newFormset[idx][item.parentField] + ',' + newFormset[idx][item.parentField].slice(0, 3)
                }
            }
        }
    }
    return closestMatch
}

export {
    applyTemplateVariables,
    applyVariablesToObject,
    parseCronExpression,
    formatAlertTiggerSchedule,
    formatAlertMetricValue,
    toNumber,
    toInt,
    toFloat,
    i18nMsg,
    getDomain,
    getPageName,
    joinCodeDesc,
    generateUUID,
    parseJsonString,
    formatAmount,
    formatTooltip1,
    formatTooltip2,
    replaceEmailId,
    formatNumber,
    formatNum,
    showFullDecimal,
    formatInteger,
    getDrilldownPath,
    getDrilldownPath_CF,
    currencyFormatter,
    applyDefaultFilter,
    applyDefaultFilter1,
    deleteSelectedFilter,
    deleteSelectedFilter1,
    setSelectedFilter,
    setSelectedFilter1,
    setSelectedFilter2,
    setDrilldownFilter,
    setDrilldownFilter1,
    convertRStoSubGraph,
    convertRStoGraph,
    convertRStoGraph_CF,
    convertRStoMultiGraph,
    convertRStoGraphYearly,
    convertRStoGraphYearly_CF,
    convertRStoGraphYearlyLine,
    convertRStoGraphYearlyPredicted,
    convertRStoGraphYearlyPredicted_CF,
    convertRStoGraphYearlyStackedAreaSpline,
    getName,
    downloadCPRules,
    downloadTRRules,
    downloadSRRules,
    downloadSolutionMapping,
    downloadSolutionRules,
    downloadBURules,
    removeSessionFilter,
    removeSessionFilter_CF,
    backgroundProcessCheck,
    convertPlural,
    ActiveStatusReverse,
    Roleformat,
    downloadChargeBack,
    getChargeBack,
    getChargeBackMonthly,
    getChargeBackByMonth,
    getPublished,
    getPublishedMonthly,
    getPublishedByMonth,
    ConvertRstoGraphdaily,
    optionsCreate,
    ConvertRstoGraphdailyStacked,
    convertRStoTimelineData
}
