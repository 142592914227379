import _ from "lodash";
import DataTable from "components/DataTable";
import useFetchRequest from "hooks/useFetchRequest";
import numeral from "numeral";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import { useYADialog } from "components/YADialog";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { Icon, IconButton } from "@mui/material";
import { useImmer } from "use-immer";
import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import { useAppController } from "context";
import { normalizeCurrency } from "utils/table";
import { useNotificationSubscription } from "hooks/useNotificationManager";
import { NotificationType } from "constants";

const CostPoolMappingView = (props) => {
    const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
    const { yearFilter, monthFilter, id, containerHeight, typeFilter, setRefresh, chargeBackMonthly } = props;
    const [filtersState, setFiltersState] = useImmer({
        globalFilter: undefined,
        filters: [],
    });
    const [controller] = useAppController();
    const {
        appDef: { settings },
    } = controller;

    const chargeBack = _.find(chargeBackMonthly, { monthNameId: monthFilter, yearNameId: yearFilter }) ? true : false

    const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState((draft) => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
    };
    const { response: uploadedFiles, error: _err, loading: loading, reloadData, } = typeFilter === 'Spend'
        ?
        (id ? useFetchRequest(`/api/dataflow/costPoolMapping/${yearFilter}/${monthFilter}?costPoolId=${id}`) : useFetchRequest(`/api/dataflow/costPoolMapping/${yearFilter}/${monthFilter}`))
        :
        (id ? useFetchRequest(`/api/dataflow/costPoolRulesBudget/${yearFilter}/${monthFilter}?costPoolId=${id}`) : useFetchRequest(`/api/dataflow/costPoolRulesBudget/${yearFilter}/${monthFilter}?allocated=${true}`));


    const handleDelete = (item) => {
        showPrompt(
            "Delete",
            "Are you sure you want to delete - [" + item["costPool__name"] + "]",
            async () => {
                var [err, data] = await fetchRequest.delete(
                    `/api/dataflow/costPoolMapping/${item["id"]}`
                );
                if (err) {
                    if (err.data?.message) {
                        showSnackbar(err.data?.message, "error")
                        setRefresh(Math.random());
                        reloadData()
                    } else {
                        showAlert("Delete", "Something went wrong. Contact your administrator.");
                    }
                } else if (data) {
                    showSnackbar(data, "success");
                    if (setRefresh) {
                        setRefresh(Math.random());
                    }
                    reloadData();
                }
            }
        );
    };

    const handleDeleteMultiple = (selectedRows) => {
        const cpIdsFromCUR = uploadedFiles.map(i => i.source === 'cloud' ? i.id : null)
        _.pullAll(selectedRows, _.compact(cpIdsFromCUR))
        showPrompt("Delete", "Are you sure you want to delete?", () =>
            deleteCostPoolMappingMultipleRecords(JSON.stringify(selectedRows))
        );
    };

    const deleteCostPoolMappingMultipleRecords = async (selectedRows) => {
        const [err, data] = await fetchRequest.post(
            `/api/dataflow/costPoolMapping`,
            selectedRows
        );

        if (err) {
            if (err.data?.message) {
                showSnackbar(err.data?.message, "error")
                setRefresh(Math.random());
                reloadData()
            } else {
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
        } else if (data) {
            showSnackbar(data, "success");
            if (setRefresh) {
                setRefresh(Math.random());
            }
            reloadData();
        }
    };

    useNotificationSubscription(NotificationType.ProcessFile, (notification) => {
        if (notification.details?.destinationTable && notification.details?.destinationTable === "costPoolMapping") {
            reloadData();
        }
    });

    let columns = [];
    if (typeFilter === "Spend")
        columns = [
            // { Header: "ID", accessor: "id", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            {
                Header: "Delete",
                disableSorting: true,
                accessor: "id",
                disableFilters: true,
                hidden: chargeBack,
                maxWidth: 70,
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            {
                                <IconButton
                                    sx={{ padding: 0 }}
                                    onClick={() => original?.source === 'cloud' ? null : handleDelete(original)}
                                >
                                    <Icon color={original?.source === 'cloud' ? 'disabled' : "error"} >delete</Icon>
                                </IconButton>}
                        </>
                    );
                },
            },
            {
                Header: "Account Code",
                accessor: "account__code",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Account Description",
                accessor: "account__description",
                dataType: "string",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Expense Type",
                accessor: "expenseType__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Cost Center Code",
                accessor: "costCentre__code",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Cost Center Name",
                accessor: "costCentre__description",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Vendor Code",
                accessor: "vendor__code",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Vendor Name",
                accessor: "vendor__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Source",
                accessor: "source",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Cost Pool",
                accessor: "costPool__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark" fontWeight="medium">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Sub Cost Pool",
                accessor: "subCostPool__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark" fontWeight="medium">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Amount",
                accessor: "exAmount",
                dataType: "currency",
                disableFilters: false,
                align: "right",
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {numeral(normalizeCurrency(value)).format("$0,0")}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "File",
                accessor: "uploadedFile__originalFileName",
                disableFilters: true,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Created On",
                accessor: "createdAt",
                disableFilters: true,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Created By",
                accessor: "createdByUser__name",
                disableFilters: true,
                Cell: ({ cell: { value } }) => {
                    if (!value) {
                        return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography>
                    }
                    else {
                        return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>
                    }
                },
            },
        ];
    else
        columns = [
            // { Header: "ID", accessor: "id", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            // {
            //     Header: "Delete",
            //     disableSorting: true,
            //     accessor: "id",
            //     disableFilters: true,
            //     hidden: chargeBack,
            //     maxWidth: 70,
            //     Cell: ({ row }) => {
            //         return (
            //             <IconButton
            //                 sx={{ padding: 0 }}
            //                 onClick={() => handleDelete(row.values)}
            //             >
            //                 <Icon color="error">delete</Icon>
            //             </IconButton>
            //         );
            //     },
            // },
            {
                Header: "Account Code",
                accessor: "account__code",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Account Description",
                accessor: "account__description",
                dataType: "string",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Expense Type",
                accessor: "expenseType__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Cost Center Code",
                accessor: "costCentre__code",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Cost Center Name",
                accessor: "costCentre__description",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Vendor Code",
                accessor: "vendor__code",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Vendor Name",
                accessor: "vendor__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Cost Pool",
                accessor: "costPool__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark" fontWeight="medium">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Sub Cost Pool",
                accessor: "subCostPool__name",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark" fontWeight="medium">
                            {value}
                        </MDTypography>
                    );
                },
            },
            {
                Header: "Budget",
                accessor: "amount",
                dataType: "currency",
                disableFilters: false,
                align: "right",
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {numeral(normalizeCurrency(value)).format("$0,0")}
                        </MDTypography>
                    );
                }
            },
            // {
            //     Header: "File",
            //     accessor: "uploadedFile__originalFileName",
            //     disableFilters: true,
            //     Cell: ({ cell: { value } }) => {
            //         return (
            //             <MDTypography variant="caption" color="dark">
            //                 {value}
            //             </MDTypography>
            //         );
            //     },
            // },
            // {
            //     Header: "Created On",
            //     accessor: "createdAt",
            //     disableFilters: true,
            //     Cell: ({ cell: { value } }) => {
            //         return (
            //             <MDTypography variant="caption" color="dark">
            //                 {value ? moment(value).format("MMM DD YYYY HH:mm:ss") : ""}
            //             </MDTypography>
            //         );
            //     },
            // },
            // {
            //     Header: "Created By",
            //     accessor: "createdByUser__name",
            //     disableFilters: true,
            //     Cell: ({ cell: { value } }) => {
            //         if (!value) {
            //             return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography>
            //         }
            //         else {
            //             return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography>
            //         }
            //     },
            // },
        ];
    if (loading) return <YASkeleton variant="loading" />;

    if (loading === false && uploadedFiles === null) {
        return (
            <div>
                {_err}
                no data
            </div>
        );
    }
    const rows = uploadedFiles.map((row) => {
        let r = {};
        Object.keys(row).forEach((k) => {
            r[k.replace(/\./g, "__")] = row[k];
        });
        return r;
    });

    let GLPopUp = (props) => {
        const [glRows, setGLRows] = useState([]);
        if (typeFilter == "Spend") {
            var getDetails = async (e, row) => {
                if (
                    e.target.innerHTML !== "" &&
                    e.target.innerHTML !== "delete" &&
                    rows.length > 0
                ) {
                    row.original.mappingType = 1;
                    row.original.year = yearFilter;
                    row.original.month = monthFilter;
                    //   row.original.mapping = 'costpool';
                    row.original.view = true;
                    row.original.type = 'costpool';
                    // console.log('row',row.original)
                    let [err, data] = await fetchRequest.post(
                        `/api/dataflow/getGLData/`,
                        JSON.stringify(row.original)
                    );
                    // console.log(data);
                    if (err) {
                        console.error("err", err);
                        // handleError(err);
                    } else {
                        let newData;
                        newData = data.map((item) => {
                            return {
                                accountCode: item["account.code"],
                                accountDescription: item["account.description"],
                                expenseType: item["expenseType.name"],
                                costCentreCode: item["costCentre.code"],
                                costCentreDescription: item["costCentre.description"],
                                vendorCode: item["vendor.code"],
                                vendorName: item["vendor.name"],
                                rgtModel: item["rgtModel"],
                                costType: item["costType"],
                                amount: item["amount"],
                                applicationID: item["applicationID"],
                                invoice: item["invoice"],
                                journalID: item["journalID"],
                                journalLine: item["journalLine"],
                                projectID: item["projectID"],
                                transactionDate: item["transactionDate"],
                                customString1: item["customString1"],
                                customString2: item["customString2"],
                                customString3: item["customString3"],
                                customString4: item["customString4"],
                                customString5: item["customString5"],
                                customDate1: item["customDate1"],
                                customDate2: item["customDate2"],
                                customDate3: item["customDate3"],
                                customDate4: item["customDate4"],
                                customDate5: item["customDate5"],
                                customNumber1: item["customNumber1"],
                                customNumber2: item["customNumber2"],
                                customNumber3: item["customNumber3"],
                                customNumber4: item["customNumber4"],
                                customNumber5: item["customNumber5"],
                            };
                        });
                        setGLRows(newData);
                    }
                }
            };

            useEffect(() => {
                getDetails(props.e, props.row);
            }, [props]);

            var glColumns = [
                {
                    Header: "Account Code",
                    accessor: "accountCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Account Description",
                    accessor: "accountDescription",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Expense Type",
                    accessor: "expenseType",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Center Code",
                    accessor: "costCentreCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Center Name",
                    accessor: "costCentreDescription",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Vendor Code",
                    accessor: "vendorCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Vendor Name",
                    accessor: "vendorName",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "RGT Model",
                    accessor: "rgtModel",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Type",
                    accessor: "costType",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Amount",
                    accessor: "amount",
                    align: "right",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {numeral(normalizeCurrency(value)).format("$0,0")}
                            </MDTypography>
                        );
                    }
                },
                {
                    Header: "Application ID",
                    accessor: "applicationID",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Journal ID",
                    accessor: "journalID",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Journal Line",
                    accessor: "journalLine",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Project ID",
                    accessor: "projectID",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Transaction Date",
                    accessor: "transactionDate",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value
                                    ? moment(value).format(defaultDateFormat || "DD/MM/YYYY")
                                    : ""}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Invoice Number",
                    accessor: "invoice",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Custom String 1",
                    accessor: "customString1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 2",
                    accessor: "customString2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },

                {
                    Header: "Custom String 3",
                    accessor: "customString3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 4",
                    accessor: "customString4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 5",
                    accessor: "customString5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom Date 1",
                    accessor: "customDate1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 2",
                    accessor: "customDate2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 3",
                    accessor: "customDate3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 4",
                    accessor: "customDate4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 5",
                    accessor: "customDate5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 1",
                    accessor: "customNumber1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 2",
                    accessor: "customNumber2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 3",
                    accessor: "customNumber3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 4",
                    accessor: "customNumber4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 5",
                    accessor: "customNumber5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
            ];
        }
        if (typeFilter == "Budget") {
            getDetails = async (e, row) => {
                if (
                    e.target.innerHTML !== "" &&
                    e.target.innerHTML !== "delete" &&
                    rows.length > 0
                ) {
                    row.original.mappingType = 1;
                    row.original.year = yearFilter;
                    row.original.month = monthFilter;
                    //   row.original.mapping = 'costpool';
                    row.original.view = true;
                    row.original.type = 'costpool';
                    // console.log('row',row.original)
                    let [err, data] = await fetchRequest.post(
                        `/api/dataflow/getGLDataBudget/`,
                        JSON.stringify(row.original)
                    );
                    // console.log(data);
                    if (err) {
                        console.error("err", err);
                        // handleError(err);
                    } else {
                        let newData;
                        newData = data.map((item) => {
                            return {
                                accountCode: item["account.code"],
                                accountDescription: item["account.description"],
                                expenseType: item["expenseType.name"],
                                costCentreCode: item["costCentre.code"],
                                costCentreDescription: item["costCentre.description"],
                                vendorCode: item["vendor.code"],
                                vendorName: item["vendor.name"],
                                amount: item["amount"],
                                projectID: item["projectID"],
                                customString1: item["customString1"],
                                customString2: item["customString2"],
                                customString3: item["customString3"],
                                customString4: item["customString4"],
                                customString5: item["customString5"],
                                customDate1: item["customDate1"],
                                customDate2: item["customDate2"],
                                customDate3: item["customDate3"],
                                customDate4: item["customDate4"],
                                customDate5: item["customDate5"],
                                customNumber1: item["customNumber1"],
                                customNumber2: item["customNumber2"],
                                customNumber3: item["customNumber3"],
                                customNumber4: item["customNumber4"],
                                customNumber5: item["customNumber5"],
                            };
                        });
                        setGLRows(newData);
                    }
                }
            };

            useEffect(() => {
                getDetails(props.e, props.row);
            }, [props]);

            glColumns = [
                {
                    Header: "Account Code",
                    accessor: "accountCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Account Description",
                    accessor: "accountDescription",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Expense Type",
                    accessor: "expenseType",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Center Code",
                    accessor: "costCentreCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Cost Center Name",
                    accessor: "costCentreDescription",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Vendor Code",
                    accessor: "vendorCode",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Vendor Name",
                    accessor: "vendorName",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Amount",
                    accessor: "amount",
                    align: "right",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {numeral(normalizeCurrency(value)).format("$0,0")}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Project ID",
                    accessor: "projectID",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        );
                    },
                },
                {
                    Header: "Custom String 1",
                    accessor: "customString1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 2",
                    accessor: "customString2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },

                {
                    Header: "Custom String 3",
                    accessor: "customString3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 4",
                    accessor: "customString4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom String 5",
                    accessor: "customString5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>)
                    }
                },
                {
                    Header: "Custom Date 1",
                    accessor: "customDate1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 2",
                    accessor: "customDate2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 3",
                    accessor: "customDate3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 4",
                    accessor: "customDate4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Date 5",
                    accessor: "customDate5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 1",
                    accessor: "customNumber1",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 2",
                    accessor: "customNumber2",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 3",
                    accessor: "customNumber3",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 4",
                    accessor: "customNumber4",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
                {
                    Header: "Custom Number 5",
                    accessor: "customNumber5",
                    Cell: ({ cell: { value } }) => {
                        return (
                            <MDTypography variant="caption" color="dark">
                                {value}
                            </MDTypography>
                        )
                    }
                },
            ];
        }
        return (
            <>
                <MDBox
                    pb={3}
                    minWidth="800px"
                    width="100%"
                >
                    <DataTable
                        variant="tile"
                        table={{ columns: glColumns, rows: glRows }}
                        containerMaxHeight={424}
                        showTotalEntries={true}
                        isSorted={true}
                        newStyle1={true}
                        noEndBorder
                        entriesPerPage={true}
                        canSearch={true}
                    ></DataTable>
                </MDBox>
            </>
        );
    };

    let getGLDetails = (e, row, columns) => {
        if (
            e.target.innerHTML !== "" &&
            e.target.innerHTML !== "delete" &&
            rows.length > 0
        )
            showCustomForm(
                "General Ledger Transactions",
                () => <GLPopUp e={e} row={row} columns={columns} />,
                null,
                null,
                null,
                '1080px'
            );
    };
    return (
        <>
            {/* <Modal open={dialogOpen} onClose={handleDialogOpen}>
                <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
                        <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                            <MDBox>
                                <MDTypography variant="h6" component="span" color="text">
                                    General Ledger Transactions
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex">
                                <IconButton onClick={handleDialogOpen} title="Close">
                                    <Icon>close</Icon>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                        <DataTable
                            variant="tile"
                            table={{ columns: glColumns, rows: glRows }}
                            containerMaxHeight={424}
                            showTotalEntries={true}
                            isSorted={true}
                            newStyle1={true}
                            noEndBorder
                            entriesPerPage={true}
                            canSearch={true}

                        >
                        </DataTable>
                    </Card>
                </MDBox>
            </Modal> */}
            <DataTable
                variant="tile"
                table={{ columns, rows }}
                containerMaxHeight={containerHeight}
                showTotalEntries={true}
                isSelectable={!chargeBack && typeFilter === "Spend"}
                newStyle1={true}
                isSorted={true}
                noEndBorder
                entriesPerPage={true}
                onRowClick={typeFilter !== "Budget" && getGLDetails}
                canSearch={true}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                canFilter={true}
                deleteMultiple={!chargeBack}
                onDeleteMultiple={handleDeleteMultiple}
                viewTable={true}
            ></DataTable>
        </>
    );
};

export default CostPoolMappingView;
