import _ from "lodash";
import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDomain } from "utils";
import MDButton from "components/MDButton";
import Dropdown from "../Dropdown";
import DynamicDropdown from "../DynamicDropdown";
import Rule from "../Rule";
import Integer from "components/YAForm/components/Integer";
import Axios from "axios";
import YASkeleton from "components/YASkeleton";
import { useYADialog } from "components/YADialog";
import fetchRequest from "utils/fetchRequest";
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import Textbox from "components/YAForm/components/Textbox";
import Float from "components/YAForm/components/Float";
import DatePicker from "components/YAForm/components/DatePicker";
import Switch from "components/YAForm/components/Switch";
// import MultiSelect from "components/YAForm/components/MultiSelect";
import DynamicMultiSelect from "../DynamicMultiSelect";
import ParamterMapping from "../parameterMapping";

const UploadRuleForm = (props) => {
    const { pkId, mode, onClose,masterDef, filtersState } = props;
    const { showSnackbar, showAlert } = useYADialog();
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const [tableName, setTableName] = useState(null)
    const [destinationColumn, setDestinationColumn] = useState(null)
    const [, setErrors] = useState({});
    const [onClear, setOnClear] = useState(false);
    const [fromCondition, setFromCondition] = useState(null);
    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(false)
    const [ruleType, setRuleType] = useState(null)
    const [functionName, setFunctionName] = useState(null)
    const [destinationColumnOptions, setDestinationColumnOptions] = useState([])
    const domain = getDomain();
    const [functionParameters, setFunctionParameters] = useState(null)
    const [funParamString, setFunParamString] = useState('')
    const [values, setValues] = useState(null);
    const [err, setErr] = useState({})


    const { watch, control, setValue, setError, formState: { errors, isSubmitting }, handleSubmit } = useForm();
    
    const fields = masterDef?.fields;

    async function getNewRule() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/uploadRules/list/?${("nc=" + Math.random()).replace(".", "")}`, null);

        const sortedRules = _.sortBy(response.data, ['sequence']);
        const filteredTableName = filtersState && filtersState.find(({id}) => id === 'tableName__displayName')?.value?.filVal
        if (filteredTableName) {
            setTableName(filteredTableName)
        }

        if (sortedRules?.length > 0) {
            setValue("sequence", sortedRules[sortedRules?.length - 1]?.sequence + 1);
            setValue("ruleType", null);
            setValue("tableName", filteredTableName??null);
            setValue("destinationColumn", null);
            setValue("conditions", null);
            setValue("defaultStringValue", null);
            setValue("defaultIntValue", null);
            setValue("defaultFloatValue", null);
            setValue("defaultDateValue", null);
            setValue("nullCheck", false);
            setValue("functionParameters", null);
            setValue("lookupTable", null);
            setValue("lookupLabel", null);
            setValue("lookupValue", null);
            setValue("lookupFilter", null);
            setLoading(false);
        } else {
            setValue("sequence", 1);
            setValue("ruleType", null);
            setValue("tableName", filteredTableName??null);
            setValue("destinationColumn", null);
            setValue("conditions", null);
            setValue("defaultStringValue", null);
            setValue("defaultIntValue", null);
            setValue("defaultFloatValue", null);
            setValue("defaultDateValue", null);
            setValue("nullCheck", false);
            setValue("functionParameters", functionParameters);
            setValue("lookupTable", null);
            setValue("lookupLabel", null);
            setValue("lookupValue", null);
            setValue("lookupFilter", null);
            setLoading(false);
        }
    }

    async function getFormData() {
        setLoading(true);
        const response = await Axios.get(`${domain}/api/uploadRules/uploadRuleItem/edit/${pkId}?${("nc=" + Math.random()).replace(".", "")}`);

        setValue("sequence", response.data["sequence"]);
        setValue("ruleType", response.data["ruleType"]);
        setValue("tableName", response.data["tableName"]);
        setValue("destinationColumn", response.data["destinationColumn"]);
        setValue("conditions", response.data["conditions"]);
        setValue("defaultStringValue", response.data["defaultStringValue"]);
        setValue("defaultIntValue", response.data["defaultIntValue"]);
        setValue("defaultFloatValue", response.data["defaultFloatValue"]);
        setValue("defaultDateValue", response.data["defaultDateValue"]);
        setValue("nullCheck", response.data["nullCheck"]);
        setValue("functionParameters", response.data["functionParameters"]);
        setValue("lookupTable", response.data["lookupTable"]);
        setValue("lookupLabel", response.data["lookupLabel"]);
        setValue("lookupValue", response.data["lookupValue"]);
        setValue("lookupFilter", response.data["lookupFilter"]);
        setValue("functionName", response.data["functionName"]);
        setValues(response.data["functionParameters"]);
        setFunctionName(response.data["functionName"]);
        setFunParamString(response.data["functionParameters"]);
        setTableName(response.data["tableName"]);
        setRuleType(response.data["ruleType"]);
        setLoading(false);
        setDestinationColumn(response.data["destinationColumn"]);
    }

    useEffect(() => {
        if (mode === "edit") {
            getFormData();
        }
        else {
            getNewRule();
        }
    }, [mode]);


    let onSubmit = async data => {
        if (functionParameters) {
            const functionParameterString = _.sortBy(functionParameters, ['index']).map((a) => {
                return a.selectedColumn
            }).join(",");

            data.functionParameters = functionParameterString
        }
        let url = mode === "edit" ? `/api/uploadRules/${pkId}` : `/api/uploadRules/new`;
        let [err, response] = await fetchRequest.post(url, data)
        if (err) {
            if (err.data?.message) {
                handleClose();
                onDialogClose();
                showSnackbar(err.data?.message, "error")
            } else {
                handleClose();
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
        }
        else if (!err && response && response.result === false) {
            if (Array.isArray(response.errors) && response.errors.length > 0) {
                response.errors.forEach((e) => {
                    if (e?.multiselect) {
                        setErr({type: "manual", message: e.message })
                    }
                    else{
                        setError(e.field, { type: "manual", message: e.message });
                    }
                    
                });
            }
        }
        else {
            handleClose();
            onDialogClose();
            showSnackbar(response.message, "success");
        }
    };

    const handleClose = useCallback(() => {
        if (onClose) onClose();
    }, []);

    if (flag == true) {
        setFlag(false)
    }

    const handleClearAssetSelection = () => {
        setFromCondition(null);
    }

    const onTableNameChange = (value) => {
        setTableName(value)
        setDestinationColumn(null)
        setFromCondition(null)
        setRuleType(null)
        setFunctionName(null)
        // value === tableName ? setTableNameChange(false) : setTableNameChange(true)
    }
    const onDestinatonColumnChange = (value) => {
        setDestinationColumn(value)
        setFromCondition(null)
        setRuleType(null)
        setFunctionName(null)
        // value === destinationColumn ? setDestinationColumn(false) : setDestinationColumnChange(true)
    }

    const onRuleTypeChange = (value) => {
        setRuleType(value)
        setFunctionName(null)
    }

    let handleAlert = (e, reverseSet) => {
        if (reverseSet && !e) {
            showAlert("Alert", "This action will result in the revocation of user access to the application");
        }
    }


    const renderContent = () => {
        if (loading) {
            return (
                <MDBox pt={1} px={3} pb={2} minWidth={1000}>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                    <MDBox>
                        <YASkeleton variant="filter-item" />
                    </MDBox>
                </MDBox>
            )
        }

        return (
            <MDBox pt={1} px={2} minWidth={600} overflow="auto">
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                    <MDBox role="form">
                        <MDBox>
                            <MDBox mb={3}>
                                <Dropdown
                                    watch={watch}
                                    setValue={setValue}
                                    disabled={isSubmitting}
                                    setTableName={onTableNameChange}
                                    control={control}
                                    fieldDef={fields[0]}
                                    errorMessage={errors["tableName"] && errors["tableName"].message}
                                />
                            </MDBox>
                            <MDBox mb={3}>
                                <DynamicDropdown
                                    watch={watch}
                                    setValue={setValue}
                                    disabled={isSubmitting}
                                    destinationColumn={destinationColumn}
                                    setDestinationColumn={onDestinatonColumnChange}
                                    setDestinationColumnOptions={setDestinationColumnOptions}
                                    control={control}
                                    fieldDef={fields[1]}
                                    errorMessage={errors["destinationColumn"] && errors["destinationColumn"].message}
                                    restrictColumns = {true}
                                />
                            </MDBox>
                            <MDBox mb={3}>
                                <Dropdown
                                    watch={watch}
                                    setValue={setValue}
                                    disabled={isSubmitting}
                                    control={control}
                                    fieldDef={fields[2]}
                                    setRuleType={onRuleTypeChange}
                                    errorMessage={errors["ruleType"] && errors["ruleType"].message}
                                />
                            </MDBox>
                            <MDBox mb={3}>
                                <Rule
                                    watch={watch}
                                    setValue={setValue}
                                    control={control}
                                    tableName={tableName}
                                    destinationColumn={destinationColumn}
                                    ruleType={ruleType}
                                    disabled={isSubmitting}
                                    fieldDef={{
                                        name: "conditions",
                                        displayName: "Rule",
                                        disableFilters: true,
                                        required: true,
                                        unique: true,
                                        dataSource: {
                                            type: "custom",
                                            parentFields: ["ruleType"],
                                            url: "/api/dataflow/resource/:source"
                                        }
                                    }}
                                    errorMessage={errors["conditions"] && errors["conditions"].message}
                                    handleAlert={handleAlert}
                                    condition={fromCondition}
                                    onfromSourceChange={onTableNameChange}
                                    setOnClear={setOnClear}
                                    onClear={onClear}
                                    onConditionChange={setFromCondition}
                                    onClearAssetSelection={handleClearAssetSelection}
                                    errors={errors}
                                    setErrors={setErrors}
                                />
                            </MDBox>
                            {ruleType === "defaultString" &&
                                <MDBox mb={3}>
                                    <Textbox watch={watch} disabled={isSubmitting} setValue={setValue} control={control} fieldDef={fields[5]} errorMessage={errors["defaultStringValue"] && errors["defaultStringValue"].message} />
                                </MDBox>
                            }
                            {ruleType === "defaultInt" &&
                                <MDBox mb={3}>
                                    <Integer watch={watch} disabled={isSubmitting} setValue={setValue} control={control} fieldDef={fields[6]} errorMessage={errors["defaultIntValue"] && errors["defaultIntValue"].message} />
                                </MDBox>
                            }
                            {ruleType === "defaultFloat" &&
                                <MDBox mb={3}>
                                    <Float watch={watch} disabled={isSubmitting} setValue={setValue} control={control} fieldDef={fields[7]} errorMessage={errors["defaultFloatValue"] && errors["defaultFloatValue"].message} />
                                </MDBox>
                            }
                            {ruleType === "defaultDate" &&
                                <MDBox mb={3}>
                                    <DatePicker watch={watch} disabled={isSubmitting} setValue={setValue} view={['year', 'month', 'day']} control={control} fieldDef={fields[8]} errorMessage={errors["defaultDateValue"] && errors["defaultDateValue"].message} />
                                </MDBox>
                            }
                            {ruleType === "copyColumn" &&
                                <MDBox mb={3}>
                                    <DynamicDropdown
                                        watch={watch}
                                        setValue={setValue}
                                        disabled={isSubmitting}
                                        control={control}
                                        fieldDef={fields[9]}
                                        errorMessage={errors["functionParameters"] && errors["functionParameters"].message}
                                    />
                                </MDBox>
                            }
                            {ruleType === "concat" &&
                                <MDBox mb={3}>
                                    <DynamicMultiSelect
                                        watch={watch}
                                        setValue={setValue}
                                        disabled={isSubmitting}
                                        control={control}
                                        values={values}
                                        setError={setErr}
                                        fieldDef={{
                                            name: "functionParameters",
                                            displayName: "Concat",
                                            schemaName: "functionParameters",
                                            disableFilters: true,
                                            type: "multiSelect",
                                            parentField: "tableName",
                                            required: false,
                                            unique: false,
                                            dataSource: {
                                                type: "custom",
                                                parentFields: ["tableName"],
                                                concat: true,
                                                url: "/api/dataflow/resource/:tableName"
                                            }
                                        }}
                                        errorMessage={err.message}
                                    />
                                </MDBox>
                            }
                            {(ruleType === "lookup" || ruleType === "lookupTable") &&
                                <>
                                    <MDBox mb={3}>
                                        <Dropdown
                                            watch={watch}
                                            setValue={setValue}
                                            disabled={isSubmitting}
                                            setTableName={onTableNameChange}
                                            control={control}
                                            fieldDef={fields[10]}
                                            errorMessage={errors["lookupTable"] && errors["lookupTable"].message}
                                        />
                                    </MDBox>
                                    <MDBox mb={3}>
                                        <DynamicDropdown
                                            watch={watch}
                                            setValue={setValue}
                                            disabled={isSubmitting}
                                            control={control}
                                            fieldDef={fields[11]}
                                            errorMessage={errors["lookupLabel"] && errors["lookupLabel"].message}
                                        />
                                    </MDBox>
                                    <MDBox mb={3}>
                                        <DynamicDropdown
                                            watch={watch}
                                            setValue={setValue}
                                            disabled={isSubmitting}
                                            control={control}
                                            fieldDef={fields[12]}
                                            errorMessage={errors["lookupValue"] && errors["lookupValue"].message}
                                        />
                                    </MDBox>
                                </>

                            }
                            {ruleType === "lookup" &&
                                <MDBox mb={3}>
                                    <Dropdown
                                        watch={watch}
                                        setValue={setValue}
                                        disabled={isSubmitting}
                                        control={control}
                                        fieldDef={fields[13]}
                                        errorMessage={errors["lookupFilter"] && errors["lookupFilter"].message}
                                    />
                                </MDBox>

                            }
                            {ruleType === "function" &&
                                <MDBox mb={3}>
                                    <Dropdown
                                        watch={watch}
                                        setValue={setValue}
                                        disabled={isSubmitting}
                                        setFunctionName={setFunctionName}
                                        control={control}
                                        fieldDef={fields[14]}
                                        errorMessage={errors["functionName"] && errors["functionName"].message}
                                    />
                                </MDBox>
                            }
                            {ruleType === "extractTag" &&
                                <>
                                    <MDBox mb={3}>
                                        <DynamicDropdown
                                            watch={watch}
                                            setValue={setValue}
                                            disabled={isSubmitting}
                                            control={control}
                                            ruleType={ruleType}
                                            fieldDef={{
                                                name: "lookupValue",
                                                displayName: "Choose Tag Column",
                                                schemaName: "lookupValue",
                                                disableFilters: true,
                                                required: false,
                                                unique: false,
                                                dataSource: {
                                                    type: "custom",
                                                    destinationColumn: true,
                                                    parentFields: ["tableName"],
                                                    url: "/api/dataflow/resource/:tableName"
                                                }
                                            }}
                                            errorMessage={errors["lookupValue"] && errors["lookupValue"].message}
                                        />
                                    </MDBox>
                                    <MDBox mb={3}>
                                        <DynamicMultiSelect
                                            watch={watch}
                                            setValue={setValue}
                                            disabled={isSubmitting}
                                            control={control}
                                            values={values}
                                            setError={setErr}
                                            fieldDef={{
                                                name: "functionParameters",
                                                displayName: "choose Tags",
                                                schemaName: "functionParameters",
                                                disableFilters: true,
                                                type: "multiSelect",
                                                parentField: "tableName",
                                                required: false,
                                                unique: false,
                                                dataSource: {
                                                    type: "custom",
                                                    object: "assetTagName",
                                                    concat: true,
                                                    url: "/api/uploadRules/lookupFilter/assetTagName/name",
                                                    labelField: "name",
                                                    keyField: "name"
                                                }
                                            }}
                                            errorMessage={err.message}
                                        />
                                    </MDBox>
                                </>

                            }

                            {
                                functionName &&
                                <MDBox mb={3}>
                                    <ParamterMapping functionName={functionName} destinationColumnOptions={destinationColumnOptions} setFunctionParameters={setFunctionParameters} funParamString={funParamString} />
                                </MDBox>
                            }

                            <MDBox mb={3}>
                                <Switch watch={watch} disabled={isSubmitting} setValue={setValue} control={control} fieldDef={fields[15]} errorMessage={errors["nullCheck"] && errors["nullCheck"].message} />
                            </MDBox>
                            {/* <MDBox mb={3}>
                                <Integer watch={watch} disabled={isSubmitting} setValue={setValue} control={control} fieldDef={fields[15]} errorMessage={errors["sequence"] && errors["sequence"].message} />
                            </MDBox> */}
                        </MDBox>
                    </MDBox>
                    <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton type="submit" variant="gradient" color="info" disabled={isSubmitting} startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : <Icon>save</Icon>}>
                            Save
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        );
    };

    return renderContent();
};


export default UploadRuleForm;