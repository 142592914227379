import MDBox from 'components/MDBox';
import _ from 'lodash';
import DataTable from 'components/DataTable';
import DraggableDataTable from 'components/DraggableDataTable';
import MDTypography from 'components/MDTypography';
import { Icon, Card, Menu, MenuItem, Tooltip, IconButton } from '@mui/material';
import { useEffect, useMemo, useState, useContext } from 'react';
import fetchRequest from 'utils/fetchRequest';
import MDButton from 'components/MDButton';
import { useYADialog } from 'components/YADialog';
import PageHeader from 'components/PageHeader';
import AnimatedRoute from 'components/AnimatedRoute';
import YASkeleton from 'components/YASkeleton';
import EmptyState from 'components/EmptyState';
import new_item_img from 'assets/svg/add_new.svg';
import useHandleError from 'hooks/useHandleError';
import moment from 'moment';
import numeral from 'numeral';
import { useAppController } from 'context';
import { useImmer } from 'use-immer';
import FilterChip from 'components/FilterChip';
import * as XLSX from 'xlsx';
import UploadRuleForm from './components/UploadRuleForm';
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import ColumnOptions from './components/ColumnOptions';

const FilterDropdown = (props) => {
  const { formId, filter, onFilterChange } = props;
  const { name, displayName, dataSource, values } = filter;
  const [options, setOptions] = useState(dataSource?.type === 'static' ? dataSource.data : []);
  const [loading, setLoading] = useState(dataSource?.type !== 'static');

  useEffect(() => {
    async function getOptions() {
      setLoading(true);
      const [error, data] = await fetchRequest.get(`/api/assetRules/${formId}/${name}`);
      if (error)
        console.error(error)
      setOptions(data || []);
      setLoading(false);
    }
    if (dataSource?.type !== 'static') getOptions();
  }, [name]);

  const handleOnFilterChange = (selectedFilter) => {
    onFilterChange({
      name: selectedFilter.schemaName??selectedFilter.name,
      type: selectedFilter.type,
      operator: selectedFilter.operator,
      values: selectedFilter.values.map(v => options.find(o => o.label === v)?.value)
    });
  }

  const sOptions = useMemo(() => options?.map(o => o.label), [options]);
  const filterValue = { name, operator: "eq", values: values?.map(v => options?.find(o => o.value === v)?.label) };
  return (
    <FilterChip loading={loading} dismissible={false} openOnMount={false} key={name} dataType={"select"} name={name} field={displayName} filterValue={filterValue} options={sOptions} onFilterSave={handleOnFilterChange} />
  );
};
let linkFieldAccessor
let linkFieldColumns = []
const GetSortPopup = (props) => {
  const { columns, rows, setRefresh, maxWidth, filtersState, handleOnFiltersStateUpdate, canFilter } = props
  const [data, setData] = useState([]);
  const { onDialogClose } = useContext(YADialogCustomFormContext);
  const { showAlert, showSnackbar } = useYADialog();

  const sortSequence = data.map( arr => {
    return {id: arr.id, sequence: arr.sequence}
  })

  const onApplyRules = async() => {
    let [err, response] = await fetchRequest.post(`/api/uploadRules/rules/applySort`, {data: sortSequence});
    if (err) {
      if (err.data?.message) {
          onDialogClose();
          setRefresh(Math.random());
          showSnackbar(err.data?.message, "error")
      } else {
        showAlert("Apply Sort", "Something went wrong. Contact your administrator.");
      }
    } else {
      onDialogClose();
      setRefresh(Math.random());
      showSnackbar(response.message, "success");
    }
  }

  const applyBtnRenderer = () => {
    return (
      <>
        <MDButton disabled={data.length ? false : true} onClick={() => onApplyRules()} color={ "info"} sx={{mr:5}} variant="contained"> {`Apply Sort`}</MDButton>
      </>
    );
  };
  rows.map( row => row.id = `${row.id}`)
  return (
    <MDBox  pb={3}
    minWidth="800px"
    width="100%"
    >
    {
      <DraggableDataTable
        primaryRender={applyBtnRenderer()}
        subTitle={"Sort the rules by drag and drop"}
        table={{ columns, rows }}
        showTotalEntries={false}
        isSorted={false}
        noEndBorder
        entriesPerPage={false}
        canSearch={true}
        sortedData={setData}
        sortColumn={"sequence"}
        maxWidth={maxWidth}
        canFilter={canFilter}
        filtersState={filtersState}
        onFiltersStateUpdate={handleOnFiltersStateUpdate}
      />
    }
  </MDBox>
  )
}

const buildColumns = (masterDef, defaultDateFormat, popup, selectedColumns) => {
  linkFieldAccessor = []
  const columns = [];
  if (Array.isArray(masterDef.fields) && masterDef.fields.length > 0) {
    const queryColumns = popup ? masterDef.fields : masterDef.fields?.filter(c => selectedColumns?.includes(c.schemaName??c.name));
    if (!masterDef.readonly && !popup)
      columns.push({
        Header: 'Actions',
        accessor: 'actions',
        align: 'left',
        disableSorting: true,
        disableFilters: true,
        minWidth: 73,
        maxWidth: 73,
      });
    queryColumns?.filter(f => !f.hidden)?.forEach((f) => {
      let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
      let accessor = f.schemaName;
      if (f.type === "condition") {
        accessor = "ruleStr"
      }
      if (f.name === 'ruleType' || f.name === 'tableName' || f.name === 'destinationColumn' || f.name === 'lookupTable' || f.name === 'lookupLabel' || f.name === 'rule') {
        accessor = `${f.name}__displayName`;
      }
      col['Header'] = f.displayName;
      col['accessor'] = accessor;
      col['Cell'] = ({ cell: { value } }) => {
        if (f.type === "currency")
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{numeral(value).format('$0,0')}</MDTypography>
        else if (f.type === "datepicker")
          return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
        else if (f.type === 'condition')
          return (value || "") !== "" ? <Tooltip placement="top" title={value}><Icon fontSize="medium" color="text">info</Icon></Tooltip> : null
        else if (f.name === 'ruleType')
          return (value || "") !== "" ? <Tooltip placement="top" title={value}><Icon fontSize="medium" color="info">{f.dataSource.data.find(({label}) => label === value)?.icon}</Icon></Tooltip> : null
        else if (f.name === 'rule')
          return (value || "") !== "" ? <Tooltip placement="top" title={value}><MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{(f.name == 'fromAsset' || f.name === 'toAsset') ? _.capitalize(value) : value}</MDTypography></Tooltip> : null
        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{(f.name == 'fromAsset' || f.name === 'toAsset') ? _.capitalize(value) : value}</MDTypography>
      };
      col['dataType'] = f.filterType || f.type
      col['disableFilters'] = f.disableFilters || false
      col['disableSorting'] = popup
      f.dataSource && f.dataSource.linkField != undefined ? linkFieldColumns.push(col) : columns.push(col);
      if (f.dataSource) {
        let columcreat = (f) => {
          let col = { align: f.align || (['integer', 'float', 'currency'].includes(f.type) ? 'right' : 'left') };
          let accessor = f.schemaName;
          if (f.type === 'dropdown') {
            accessor = `${f.dataSource.object}__${f.dataSource.labelField}`;
          }
          col['Header'] = f.displayName;
          col['accessor'] = accessor;
          col['Cell'] = ({ cell: { value } }) => {
            if (f.type === "currency")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{numeral(value).format('$0,0')}</MDTypography>
            else if (f.type === "datepicker")
              return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value ? moment(value).format(f.format || defaultDateFormat) : ""}</MDTypography>
            return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={f.emphasize && "medium"}>{value?.toString()}</MDTypography>
          };
          col['dataType'] = f.filterType || f.type
          col['disableFilters'] = f.disableFilters || false
          col['disableSorting'] = popup
          linkFieldColumns.push(col);
          linkFieldAccessor.push(accessor)
          if (f.dataSource) {
            linkFieldCheck(f)
          }
        }
        let linkFieldCheck = (f) => {
          if (f.dataSource.linkField) {
            columcreat(f.dataSource.linkField)
          }
        }
        f.dataSource.linkField ? linkFieldAccessor.push(accessor) : linkFieldAccessor
        linkFieldCheck(f)
        if (linkFieldColumns.length > 0) {
          linkFieldColumns = linkFieldColumns.reverse()
          linkFieldColumns.forEach(item => { columns.push(item) })
          linkFieldColumns = []
        }
      }
    });
  }
  return columns;
};

const buildRows = (pkColumn, data, onEdit, onDelete, isDeleting) => {
  const rows = [];
  if (Array.isArray(data) && data.length > 0) {
    data.forEach((r) => {
      let row = {};
      Object.keys(r).forEach((k) => {
        row[k.replace(/\./g, '__')] = r[k];
      });
      if (linkFieldAccessor.length > 0) {
        let nameSet = row[linkFieldAccessor[0]].split(" | ").reverse()
        for (let i = 0; i < linkFieldAccessor.length; i++) {
          row[linkFieldAccessor[i]] = nameSet[i]
        }
      }

      row['actions'] =
        r?.taxonomy === true ? (
          <span></span>
        ) : (
          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }}>
            <Tooltip title="Edit" placement="top">
              <IconButton sx={{ paddingRight: 1 }} onClick={() => isDeleting ? "" : onEdit(r[pkColumn])}><Icon fontSize="small">edit</Icon></IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <IconButton sx={{ paddingRight: 1 }} onClick={() => isDeleting ? "" : onDelete(r[pkColumn])}><Icon fontSize="small" color="error">delete</Icon></IconButton>
            </Tooltip>
          </MDBox>
        );
      rows.push(row);
    });
  }
  // if(rows.length > 0) rows.sort((a, b)=> a?.sequence - b?.sequence)
  return rows;
};

const filtersInitiaized = (filters) => {
  let initiaized = false;
  filters?.forEach(f => {
    if (f.values && Array.isArray(f.values) && f.values?.length > 0) {
      initiaized = true;
      return;
    }
  });
  return initiaized;
}



const RuleMapping = (props) => {
  // const { containerHeight, tableName, categoryFilter, subCategoryFilter } = props;
  const { containerHeight, tableName } = props;
  const [step, setStep] = useState('LOADING');
  const handleError = useHandleError();
  const [masterDef, setMasterDef] = useImmer(null);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false)
  const [list, setList] = useState([])
  const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });
  const [controller,] = useAppController();
  const { appDef: { settings } } = controller;
  const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
  const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
  const selectedColumnsInitial = masterDef?.fields?.filter((c) => !c.optional).map((c) => c.name);
  const [showOptions, setShowOptions] = useState(false);
  const [pageOptions, setPageOptions] = useState({ selectedColumns: selectedColumnsInitial });

  const onPageOptionsChange = (pageOptionsVal) => {
    setPageOptions(prev => ({ ...prev, ...pageOptionsVal }));
  };

  useEffect(async () => {
    let [err, data] = await fetchRequest.get(`/api/uploadRules/listTables`);
    if (err) {
      if (err.data?.message) {
        setRefresh(Math.random());
        showSnackbar(err.data?.message, "error")
      } else {
        setRefresh(Math.random());
      }
    }
    else if (data) {
      let tableValue = tableName??""
      if (typeof tableValue !== 'object' && data.length > 0) {
        let filters = []
        let val = data.find(({value}) => value === tableValue)?.label??tableValue
        let filVal = data.find(({value}) => value === tableValue)?.value??tableValue
        let filTableName = {
          id: 'tableName__displayName',
          value: {
              type: 'textbox',
              operator: 'eq',
              session: false,
              values: [val],
              filVal: filVal
          }
        }
        tableValue ? filters.push(filTableName) : ""
        setFiltersState(draft => {
          draft.filters = filters;
        });
      }
      if (typeof tableValue === 'object' && data.length > 0) {
        let filters = []
        let val = tableValue.map( val => {
          val = data.find(({value}) => value === val)?.label??val
          return val
        })
        let filVal = tableValue.map( val => {
          let filVal = data.find(({value}) => value === val)?.value??val
          return filVal
        })        
        let filTableName = {
          id: 'tableName__displayName',
          value: {
              type: 'textbox',
              operator: 'eq',
              session: false,
              values: val,
              filVal: filVal[0]
          }
        }
        tableValue ? filters.push(filTableName) : ""
        setFiltersState(draft => {
          draft.filters = filters;
        });
      }
    }
  },[refresh])

  useEffect(() => {
    if (list && filtersState.filters.length > 0) {
        setFiltersState(draft => {
            draft.filters = draft.filters.map( filter => {
                let availableValues = [...new Set(list.map( row => row[filter.id]))]
                let updatedValues = filter.value.values.filter( val => availableValues.includes(val))
                if (filter.value.values.length > 1 && updatedValues.length > 0)
                  filter.value.values = updatedValues
                if (updatedValues.length == 0)
                  filter.value.values = [filter.value.values[0]]
                return filter
            });
        });   
      }
  },[list, refresh])
  
  const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
    setFiltersState(draft => {
      draft.globalFilter = latestGlobalFilter;
      draft.filters = latestFilters;
    });
  }


  const handleClose = () => {
    setRefresh(Math.random());
  };

  const getUploadRuleForm = async () => {
    const [err, data] = await fetchRequest.get(`/api/uploadRules/uploadRuleForm`);
    if (err) {
      handleError(err);
    } 
    if (data) {
      return data;
    }
  };

  const handleEdit = async (pkId) => {
    const uploadForm = await getUploadRuleForm();
    showCustomForm(`Edit Upload Rule`, () => <UploadRuleForm masterDef = {uploadForm} onClose={handleClose} mode="edit" pkId={pkId}  />, handleClose, "edit", pkId, "md");
    handleCloseMenuItem();
  };


  const deleteMaster = async (pkId) => {
    setIsDeleting(true)
    const [err, data] = await fetchRequest.delete(`/api/uploadRules/${pkId}`);
    if (err) {
      if (err.data?.message) {
        handleClose();
        showSnackbar(err.data?.message, "error")
      } else {
        handleClose();
        showAlert("Delete", "Something went wrong. Contact your administrator.");
      }
    }
    else
      if (data && data.result === true) {
        showSnackbar('Data deleted successfully', 'success');
        handleClose();
      }
      else if (data && data.result === false) {
        showAlert('Delete', data.message || 'Something went wrong. Contact your administrator.');
      }
    setIsDeleting(false)
  };

  const handleDeleteSuccess = (pkId) => {
    deleteMaster(pkId);
  };
  const handleCloseMenuItem = (a) => {
    setOpenMenu(false)
    if (a)
      a();
  };

  const handleDelete = (pkId) => {
    showPrompt('Delete', 'Are you sure you want to delete?', () => handleDeleteSuccess(pkId));
  };

  const deleteMasterMultipleRecords = async (selectedRows) => {
    setIsDeleting(true)
    const [err, data] = await fetchRequest.post(`/api/uploadRules/deleteMultiple`, selectedRows);

    if (err) {
      if (err.data?.message) {
        handleClose();
        setRefresh(Math.random());
        showSnackbar(err.data?.message, "error")
      } else {
        handleClose();
        setRefresh(Math.random());
        showAlert("Delete", "Something went wrong. Contact your administrator.");
      }
    }
    else if (data && data.result === true) {
      showSnackbar('Data deleted successfully', 'success');
      handleClose()
      // setRefresh(Math.random());

    }
    setIsDeleting(false)
  }
  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  };

  const handleDeleteMultiple = (selectedRows) => {
    showPrompt('Delete', 'Are you sure you want to delete?', () => deleteMasterMultipleRecords(JSON.stringify(selectedRows)));
  };

  useEffect(() => {
    async function getMasterDef() {
      let [err, data] = await fetchRequest.get(`/api/uploadRules/uploadRuleForm`);
      if (err) {
        handleError(err);
      } else {
        setMasterDef(data);
        const selectedColumns = data?.fields?.filter((c) => !c.optional).map((c) => c.name)
        setColumns(buildColumns(data, defaultDateFormat, false, selectedColumns));
        setPageOptions({ selectedColumns: data?.fields?.filter((c) => !c.optional).map((c) => c.name) })
      }
    }
    getMasterDef();
  },[]);

  useEffect(() => {
    if (masterDef) {
      setColumns(buildColumns(masterDef, defaultDateFormat, false, pageOptions.selectedColumns)); 
    }
  },[pageOptions]);

  useEffect(() => {
    async function getList() {
      setLoading(true);
        let [err, data] = await fetchRequest.get(`/api/uploadRules/list/`);
        if (err) {
          handleError(err);
        } else {
          if (data && Array.isArray(data) && data.length > 0) {
            setList(data)
            setRows(buildRows(masterDef?.pkColumn || 'id', data, handleEdit, handleDelete, isDeleting));
            setStep('LOADED');
          } else {
            setRows([]);
            setStep('EMPTY');
          }
        }
        setLoading(false);
      
    }
      getList();
  }, [isDeleting, refresh]);

  const handleSort = async () => {
    const columns = buildColumns(masterDef, defaultDateFormat, true, pageOptions.selectedColumns);
    handleSortPopup(columns, list, masterDef)
    handleCloseMenuItem();
  };

  const handleSortPopup = (columns, rows) => {
    showCustomForm(
      "Sort Rules",
      () => (<GetSortPopup columns={columns} rows={rows} filtersState={filtersState} handleOnFiltersStateUpdate={handleOnFiltersStateUpdate} setRefresh={setRefresh} canFilter={canFilter} maxWidth={"lg"}/>),
      null,
      null,
      null,
      'lg'
    );
  }

  if (step === 'LOADING') {
    return <YASkeleton variant="dashboard-loading" />;
  }

  const { displayName, singularDisplayName, desc, message, canFilter } = masterDef;

  const handleAddButtonClick = () => {
    showCustomForm(`New ${singularDisplayName || displayName}`, () => <UploadRuleForm onClose={handleClose} masterDef = {masterDef} filtersState={filtersState.filters.filter(({id}) => id === 'tableName__displayName')} />, handleClose, null, null, 'md');
    handleCloseMenuItem();
  };

  const handleCloseMenu = () => setOpenMenu(false);

  const handleOnOptionsClick = () => {
    setShowOptions(true);
  }
  const handleOnOptionsClose = () => {
    setShowOptions(false);
  }

  const handleOnOptionsApply = (selectedColumnIds) => {
    onPageOptionsChange({ ...pageOptions, selectedColumns: selectedColumnIds })
  }

  const handleOnFilterChange = (selectedFilter) => {
    setMasterDef((draft) => {
      let filter = draft.filters?.find(f => f.schemaName === selectedFilter.name || f.name === selectedFilter.name);
      filter.operator = selectedFilter.operator;
      filter.values = selectedFilter.values;
    });
  };

  let handlePageOption = () => {
    setPageOptions({ selectedColumns: selectedColumnsInitial })
  }

  const handleDownload = async () => {
    let newData = list.map(item => {
      return {
        "Rule Type": item["ruleType__displayName"],
        "Table Name": item["tableName__displayName"],
        "Destination Column": item['destinationColumn__displayName'],
        "Filter": item["conditions"],
        "String": item["defaultStringValue"],
        "Integer": item["defaultIntValue"],
        "Float": item["defaultFloatValue"],
        "Date": item["defaultDateValue"],
        "Function Parameters": item["functionParameters"],
        "Lookup Table": item["lookupTable__displayName"],
        "Lookup Label": item["lookupLabel__displayName"],
        "Lookup Value": item["lookupValue"],
        "Lookup Filter": item["lookupFilter"],
        "Function": item["functionName"],
        "Apply on empty fields": item["nullCheck"],
        "Sequence": item["sequence"],
      }
    })
    const date = new Date()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(newData)
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, `uploadRules_`+date.toISOString().slice(0,10)+`.xlsx`)
    await fetchRequest.post(`/api/dataflow/createLogger`, { message: `Downloaded upload rules}` })
    handleCloseMenuItem();
  };

  const renderPrimaryActions = () => {
    return (
      <>
        <MDBox pt={0} mt={0} display="flex" flexDirection="row">
          <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          >
            {<>
              <MenuItem key={'download'} onClick={handleDownload}>{"Download"}</MenuItem>
              <MenuItem key={'sort'} onClick={handleSort}>{"Sort Rules"}</MenuItem>
            </>
            }
          </Menu>
           <MDButton data-testid={"addnew"} variant="outlined" color="info" startIcon={<Icon>add</Icon>} onClick={handleAddButtonClick}>
            Add New
          </MDButton>
          &nbsp;
          <MDBox mt={0} mr={1} pt={0}>
            <MDButton
              // size="medium"
              disableRipple
              color="dark"
              variant="text"
              disabled={false}
              onClick={handleOpenMenu}
              sx={{ "& .MuiIcon-root": { fontSize: "20px!important" } }}
              iconOnly
            >
              <Icon px={0} py={0}>more_horiz</Icon>
            </MDButton>
          </MDBox>

        </MDBox>

      </>
    );
  };
  const renderPrimaryActions1 = () => {
    return (
      <>
          <MDBox flexDirection={"row"}>
            <MDButton
              data-testid={"addnew"}
              variant="outlined"
              color="info"
              sx={{ mr: 4 }}
              startIcon={<Icon>add</Icon>}
              onClick={handleAddButtonClick}
            >
              Add New
            </MDButton>
          </MDBox>
      </>
    );
  };



  const renderFilters = () => {
    return (
      <>
        {masterDef.filters?.map((f) => (
          <FilterDropdown key={f.schemaName??f.name} filter={f} onFilterChange={handleOnFilterChange} />
        ))}
      </>
    )
  }

  const defaultFilteresInitiaized = filtersInitiaized(masterDef?.filters);

  return (
    <>
      <PageHeader
        title={displayName}
        subtitle={desc}
        message={message}
        hideBreadcrumbs={true}
        settingsHeaderFormat={true}
        // primaryActionComponent={renderPrimaryActions}
        anchor={displayName}
      />
      {<MDBox p={3} pt={1}>
        {step === 'EMPTY' && (masterDef.filters?.length === 0 || !defaultFilteresInitiaized) && (
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100vh - 300px)"
          >
            <EmptyState
              size="large"
              image={new_item_img}
              title={`No ${displayName} Yet`}
              description={
                !masterDef.readonly
                  ? `Click on the '+ Add New Upload Rule' button to add a new ${(
                    singularDisplayName || displayName
                  ).toLowerCase()}.`
                  : undefined
              }
              actions={renderPrimaryActions1}
            />
          </MDBox>
        )}

        {(step === 'LOADED' || (step === 'EMPTY' && masterDef.filters?.length > 0) && defaultFilteresInitiaized) &&
          <>
            <Card sx={{ height: '100%' }} px={0}>
              <DataTable
                containerMaxHeight={containerHeight??500}
                table={{ columns, rows }}
                showTotalEntries={true}
                isSorted={true}
                newStyle1={true}
                isSelectable={true}
                noEndBorder
                entriesPerPage={true}
                canSearch={true}
                primaryRender={renderPrimaryActions()}
                filtersComponent={renderFilters()}
                canFilter={canFilter}
                loading={loading}
                deleteMultiple={masterDef.readonly || masterDef.taxonomy ? false : true}
                onDeleteMultiple={handleDeleteMultiple}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                onOptionsClick={handleOnOptionsClick}
                onPageOptionsChange={onPageOptionsChange}
                handlePageOption={handlePageOption}
                
              />
              {showOptions && (
                <ColumnOptions
                  columnsDef={masterDef?.fields.filter(item=> item.type !== 'icon')}
                  selectedColumnsInitial={pageOptions.selectedColumns}
                  onOptionsClose={handleOnOptionsClose}
                  onOptionsApply={handleOnOptionsApply}
                />
              )}
            </Card>
          </>
        }

      </MDBox>}
    </>
  );
};

export default AnimatedRoute(RuleMapping);
